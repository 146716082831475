import React, { useEffect, useState } from 'react'
import CustomTextField from '../../../../../components/custom_text_field/CustomTextField'
import CustomAutoCompleteDropdown from '../../../../../components/custom_auto_complete_dropdown/CustomAutoCompleteDropdown'
import { IoArrowBackSharp } from "react-icons/io5";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import './clientFormStyle.css'
import CustomTextArea from '../../../../../components/custom_text_area/CustomTextArea';
import * as Yup from "yup";
import { useFormik } from 'formik';
import { get_status } from '../../../../../api/statusApis';
import { get_cient_types, get_city_lists, get_country_lists, get_state_lists } from '../../../../../api/lookupApis';
import { ErrorToast, SuccessToast } from '../../../../../toast/toasts';
import { Client_document_delete, client_registration, get_client_by_id, update_client_details, upload_document_for_gst } from '../../../../../api/clientApis';
import { CommonHelpers } from '../../../../../services/commonHelpers';
import { RiDownloadLine } from "react-icons/ri";
import { MdDeleteOutline } from "react-icons/md";
import '../../../process_ecr/flight_tickets/flight_ticket_additional_info/flightTicketAdditionalInfo.css'
import CustomDatePicker from '../../../../../components/custom_date_picker/CustomDatePicker';
import dayjs from 'dayjs';
function ClientForm({ is_open_modal, close_modal }) {

    const navigate = useNavigate()
    const [status, setStatus] = useState([])
    const [clientType, setClientType] = useState([])
    const [countryLists, setCountryLists] = useState([])
    const [stateLists, setStateLists] = useState([])
    const [cityLists, setCityLists] = useState([])
    const { client_id } = useParams()
    const location = useLocation()
    const [isView, setIsView] = useState(null)
    const [fileAttachments_Client, setFileAttachments_Client] = useState([])
    const [deletedAttachments_Client, setDeletedAttachments_Client] = useState([])
    const [AadharAttachments_Client, setAadharAttachments_Client] = useState([])
    const [deletedAadharAttachments_Client, setDeletedAadharAttachments_Client] = useState([])
    const [PanAttachments_Client, setPanAttachments_Client] = useState([])
    const [deletedPanAttachments_Client, setDeletedPanAttachments_Client] = useState([])
    const [ContractAttachments_Client, setContracAttachments_Client] = useState([])
    const [deletedContractAttachments_Client, setDeletedContractAttachments_Client] = useState([])
    const [PassportAttachments_Client, setPassportAttachments_Client] = useState([])
    const [deletedPassporttAttachments_Client, setDeletedPassportAttachments_Client] = useState([])
    const Exempted = [
        { label: "Yes", value: 1, },
        { label: "No", value: 0, }
    ]
    function handleFileSelectClient(event) {
        const fileInput = event.target;
        const file = fileInput.files[0];
        const fileName = file.name;
        const fileType = file.type.split("/")[1];
        set_attachment_list_Client({ file: file, fileName: fileName, fileType: fileType })
    }
    function handleAadharSelectClient(event) {
        const fileInput = event.target;
        const file = fileInput.files[0];
        const fileName = file.name;
        const fileType = file.type.split("/")[1];
        set_attachment_list_Client_adhar({ file: file, fileName: fileName, fileType: fileType })
    }
    function handlepanSelectClient(event) {
        const fileInput = event.target;
        const file = fileInput.files[0];
        const fileName = file.name;
        const fileType = file.type.split("/")[1];
        set_attachment_list_Client_Pan({ file: file, fileName: fileName, fileType: fileType })
    }
    function handleContractSelectClient(event) {
        const fileInput = event.target;
        const file = fileInput.files[0];
        const fileName = file.name;
        const fileType = file.type.split("/")[1];
        set_attachment_list_Client_Contract({ file: file, fileName: fileName, fileType: fileType })
    }
    function handlePassportSelectClient(event) {
        const fileInput = event.target;
        const file = fileInput.files[0];
        const fileName = file.name;
        const fileType = file.type.split("/")[1];
        set_attachment_list_Client_Passport({ file: file, fileName: fileName, fileType: fileType })
    }
    const set_attachment_list_Client = (item) => {
        setFileAttachments_Client([...fileAttachments_Client, item])
    }
    const set_attachment_list_Client_adhar = (item) => {
        setAadharAttachments_Client([...AadharAttachments_Client, item])
    }
    const set_attachment_list_Client_Pan = (item) => {
        setPanAttachments_Client([...PanAttachments_Client, item])
    }
    const set_attachment_list_Client_Contract = (item) => {
        setContracAttachments_Client([...ContractAttachments_Client, item])
    }
    const set_attachment_list_Client_Passport = (item) => {
        setPassportAttachments_Client([...PassportAttachments_Client, item])
    }
    const delete_particular_attachment_details_Client = (index, item) => {
        const formData = new FormData();
        fileAttachments_Client.splice(index, 1)
        setFileAttachments_Client([...fileAttachments_Client])
        if (item.CGD_id) {
            setDeletedAttachments_Client([...deletedAttachments_Client, item.CGD_id])
            formData.append('Id', item.CGD_id);
            if (client_id) {
                formData.append('clientId', client_id);
                Client_document_delete(formData).then((response_1) => {
                    console.log(response_1)
                }).catch((error) => {
                    console.log(error)
                })
            }
        }

    }
    const delete_particular_attachment_details_Client_Aadhar = (index, item) => {
        const formData = new FormData();
        AadharAttachments_Client.splice(index, 1)
        setAadharAttachments_Client([...AadharAttachments_Client])
        if (item.CGD_id) {
            setDeletedAadharAttachments_Client([...deletedAadharAttachments_Client, item.CGD_id])
            formData.append('Id', item.CGD_id);
            if (client_id) {
                formData.append('clientId', client_id);
                Client_document_delete(formData).then((response_1) => {
                    console.log(response_1)
                }).catch((error) => {
                    console.log(error)
                })
            }
        }
    }
    const delete_particular_attachment_details_Client_Pan = (index, item) => {
        const formData = new FormData();
        PanAttachments_Client.splice(index, 1)
        setPanAttachments_Client([...PanAttachments_Client])
        if (item.CGD_id) {
            setDeletedPanAttachments_Client([...deletedPanAttachments_Client, item.CGD_id])
            formData.append('Id', item.CGD_id);
            if (client_id) {
                formData.append('clientId', client_id);
                Client_document_delete(formData).then((response_1) => {
                    console.log(response_1)
                }).catch((error) => {
                    console.log(error)
                })
            }
        }
    }
    const delete_particular_attachment_details_Client_Contract = (index, item) => {
        const formData = new FormData();
        ContractAttachments_Client.splice(index, 1)
        setContracAttachments_Client([...ContractAttachments_Client])
        if (item.CGD_id) {
            setDeletedContractAttachments_Client([...deletedContractAttachments_Client, item.CGD_id])
            formData.append('Id', item.CGD_id);
            if (client_id) {
                formData.append('clientId', client_id);
                Client_document_delete(formData).then((response_1) => {
                    console.log(response_1)
                }).catch((error) => {
                    console.log(error)
                })
            }

        }
    }
    const delete_particular_attachment_details_Client_Passport = (index, item) => {
        const formData = new FormData();
        PassportAttachments_Client.splice(index, 1)
        setPassportAttachments_Client([...PassportAttachments_Client])
        if (item.CGD_id) {
            setDeletedPassportAttachments_Client([...deletedPassporttAttachments_Client, item.CGD_id])
            formData.append('Id', item.CGD_id);
            if (client_id) {
                formData.append('clientId', client_id);
                Client_document_delete(formData).then((response_1) => {
                    console.log(response_1)
                }).catch((error) => {
                    console.log(error)
                })
            }
        }
    }
    useEffect(() => {
        if (location.pathname.split('/').pop() === "view_client") {
            setIsView(true)
        }
        getStatus()
        getclientType()
        getCountryLists()
        if (client_id) {
            get_client_by_id(client_id).then((response) => {
                const response_data = response.data.data
                const gst_documents = response?.data?.data?.documents?.gst
                const aadhar = response?.data?.data?.documents?.aadhar
                const pan = response?.data?.data?.documents?.pan
                const passport = response?.data?.data?.documents?.passport
                const contract = response?.data?.data?.documents?.contract
                formik.setValues({
                    ...formik.values,
                    "CI_name": response_data.CI_name,
                    "CI_statusId": Number(response_data.CI_statusId),
                    "CI_countryid": response_data.CI_countryid,
                    "CI_stateId": response_data.CI_stateId,
                    "CI_city": response_data.CI_city,
                    "CI_email": response_data.CI_email,
                    "CI_phone": response_data.CI_phone,
                    "CI_address": response_data.CI_address,
                    "CI_pincode": response_data.CI_pincode,
                    "CI_Remarks": response_data.CI_Remarks,
                    "CI_client_type": response_data.CI_client_type,
                    "CI_gst_no": response_data.CI_gst_no,
                    "CI_exampted": response_data.CI_exampted,
                    "CI_aadhar_number": response_data.CI_aadhar_number,
                    "CI_Pan_No": response_data.CI_Pan_No,
                    "CI_passport_num": response_data.CI_passport_num,
                    "CI_contract_start": response_data.CI_contract_start,
                    "CI_contract_end": response_data.CI_contract_end,
                });
                getStateLists(response_data.CI_countryid)
                setFileAttachments_Client(gst_documents)
                setAadharAttachments_Client(aadhar)
                setContracAttachments_Client(contract)
                setPassportAttachments_Client(passport)
                setPanAttachments_Client(pan)
                // getCityLists(response_data?.country_id, response_data?.state_id)
            }).catch((error) => {
                console.log(error)
            })
        }
    }, [])

    const formik = useFormik({
        initialValues: {
            "CI_name": "",
            "CI_statusId": 1,
            "CI_countryid": "",
            "CI_stateId":"",
            "CI_city": "",
            "CI_email": "",
            "CI_phone": "",
            "CI_address": "",
            "CI_pincode": "",
            "CI_Remarks": "",
            "CI_client_type": "",
            "CI_gst_no": "",
            "CI_exampted": 0,
            "CI_aadhar_number": "",
            "CI_Pan_No": "",
            "CI_passport_num": "",
            "CI_contract_start": "",
            "CI_contract_end": "",
        },
        validationSchema: Yup.object({
            CI_name: Yup.string().required("Name is required"),
            // cd_email: Yup.string().email("Invalid email address").required("Email is required"),
            CI_statusId: Yup.string().required("Status is required"),
            CI_client_type: Yup.string().required("Client Type is required"),
            CI_exampted: Yup.string().required("Exampted is required"),
        }),
        onSubmit: (values) => {
            const formData = new FormData();


            if (!client_id) {
                for (const key in values) {
                    formData.append(key, values[key]);
                }
                // if (fileAttachments_Client.length > 0) {
                //     fileAttachments_Client.map((item, index) => {
                //         formData.append(`file[${index}]`, item.file);
                //     })
                // }
                client_registration(formData).then((response) => {
                    if (response.status == 200) {
                        if (fileAttachments_Client.length > 0 || AadharAttachments_Client.length > 0 || PanAttachments_Client.length > 0 || PassportAttachments_Client.length > 0 || ContractAttachments_Client.length > 0) {
                            const formData = new FormData();
                            fileAttachments_Client.map((item, index) => {
                                formData.append(`documents[gst_doc][file][${index}]`, item.file);
                                formData.append(`documents[gst_doc][file_type][${index}]`, 'gst');
                            })
                            AadharAttachments_Client.map((item, index) => {
                                formData.append(`documents[aadhar][file][${index}]`, item.file);
                                formData.append(`documents[aadhar][file_type][${index}]`, 'aadhar');
                            })
                            PanAttachments_Client.map((item, index) => {
                                formData.append(`documents[pan][file][${index}]`, item.file);
                                formData.append(`documents[pan][file_type][${index}]`, 'pan');
                            })
                            PassportAttachments_Client.map((item, index) => {
                                formData.append(`documents[passport][file][${index}]`, item.file);
                                formData.append(`documents[passport][file_type][${index}]`, 'passport');
                            })
                            ContractAttachments_Client.map((item, index) => {
                                formData.append(`documents[contract][file][${index}]`, item.file);
                                formData.append(`documents[contract][file_type][${index}]`, 'contract');
                            })
                            formData.append('clientId', response?.data?.insertedId);
                            upload_document_for_gst(formData).then((response_1) => {
                                console.log(response_1)
                            }).catch((error) => {
                                console.log(error)
                            })
                        }
                        if (is_open_modal) {
                            close_modal(false)
                        } else {
                            navigate(-1)
                        }
                        SuccessToast(response.data.message)
                    }
                }).catch((error) => {
                    let message = "";
                    if (error?.data?.error) {
                        message = error?.data?.error
                    } else {
                        for (const key in error.data) {
                            if (error.data.hasOwnProperty(key)) {
                                const keyMessages = error.data[key].map(message => message);
                                message = message.concat(keyMessages);
                            }
                        }
                    }
                    ErrorToast(message)
                })
            } else {
                // if (fileAttachments_Client) {
                //     const formData = new FormData();
                //     formData.append('clientId', client_id);
                //     fileAttachments_Client.map((item) => {
                //         console.log(item)
                //         formData.append('Id', item.CGD_id);
                //         Client_document_delete(formData).then((response_1) => {
                //             console.log(response_1)
                //         }).catch((error) => {
                //             console.log(error)
                //         })
                //     })
                // }

                // if (AadharAttachments_Client) {
                //     const formData = new FormData();
                //     formData.append('clientId', client_id);
                //     AadharAttachments_Client.map((item) => {
                //         console.log(item)
                //         formData.append('Id', item.CGD_id);
                //         Client_document_delete(formData).then((response_1) => {
                //             console.log(response_1)
                //         }).catch((error) => {
                //             console.log(error)
                //         })
                //     })
                // }
                // if (PanAttachments_Client) {
                //     const formData = new FormData();
                //     formData.append('clientId', client_id);
                //     PanAttachments_Client.map((item) => {
                //         console.log(item)
                //         formData.append('Id', item.CGD_id);
                //         Client_document_delete(formData).then((response_1) => {
                //             console.log(response_1)
                //         }).catch((error) => {
                //             console.log(error)
                //         })
                //     })
                // }
                // if (PassportAttachments_Client) {
                //     const formData = new FormData();
                //     formData.append('clientId', client_id);
                //     PassportAttachments_Client.map((item) => {
                //         console.log(item)
                //         formData.append('Id', item.CGD_id);
                //         Client_document_delete(formData).then((response_1) => {
                //             console.log(response_1)
                //         }).catch((error) => {
                //             console.log(error)
                //         })
                //     })
                // }
                // if (ContractAttachments_Client) {
                //     const formData = new FormData();
                //     formData.append('clientId', client_id);
                //     ContractAttachments_Client.map((item) => {
                //         console.log(item)
                //         formData.append('Id', item.CGD_id);
                //         Client_document_delete(formData).then((response_1) => {
                //             console.log(response_1)
                //         }).catch((error) => {
                //             console.log(error)
                //         })
                //     })
                // }
                for (const key in values) {
                    if (values[key]) {
                        formData.append(key, values[key]);
                    }

                }
                update_client_details(client_id, formData).then((response) => {
                    if (response.status == 200) {
                        if (fileAttachments_Client.length > 0 || AadharAttachments_Client.length > 0 || PanAttachments_Client.length > 0 || PassportAttachments_Client.length > 0 || ContractAttachments_Client.length > 0) {
                            const formData = new FormData();
                            fileAttachments_Client.map((item, index) => {
                                formData.append(`documents[gst_doc][file][${index}]`, item.file);
                                formData.append(`documents[gst_doc][file_type][${index}]`, 'gst');
                            })
                            AadharAttachments_Client.map((item, index) => {
                                formData.append(`documents[aadhar][file][${index}]`, item.file);
                                formData.append(`documents[aadhar][file_type][${index}]`, 'aadhar');
                            })
                            PanAttachments_Client.map((item, index) => {
                                formData.append(`documents[pan][file][${index}]`, item.file);
                                formData.append(`documents[pan][file_type][${index}]`, 'pan');
                            })
                            PassportAttachments_Client.map((item, index) => {
                                formData.append(`documents[passport][file][${index}]`, item.file);
                                formData.append(`documents[passport][file_type][${index}]`, 'passport');
                            })
                            ContractAttachments_Client.map((item, index) => {
                                formData.append(`documents[contract][file][${index}]`, item.file);
                                formData.append(`documents[contract][file_type][${index}]`, 'contract');
                            })
                            formData.append('clientId', client_id);
                            upload_document_for_gst(formData).then((response_1) => {
                                console.log(response_1)
                            }).catch((error) => {
                                console.log(error)
                            })
                        }
                        navigate(-1)
                        SuccessToast(response.data.message)
                    }
                }).catch((error) => {
                    console.log(error)
                    let message = "";
                    for (const key in error?.data) {
                        if (error?.data.hasOwnProperty(key)) {
                            const keyMessages = error?.data[key].map(message => message);
                            message = message.concat(keyMessages);
                        }
                    }
                    ErrorToast(message)
                })
            }


        }
    });
    const getStatus = () => {
        get_status().then((response) => {
            const data = response.map((item) => {
                return {
                    label: item.status_name,
                    value: item.id,
                };
            });
            setStatus(data)
        }).catch((error) => {
            console.log(error)
        })
    }

    //changed by najma on 4-10-2024
    const getclientType = () => {
        get_cient_types().then((response) => {
            console.log(response)
            const data = response?.data?.map((item) => {
                return {
                    label: item.CLTL_name,
                    value: item.CLTL_id,
                };
            });

            setClientType(data)
        }).catch((error) => {
            console.log(error)
        })
    }

    const getCountryLists = () => {
        get_country_lists().then((response) => {
            const data = response?.data?.map((item) => {
                return {
                    label: item.country_name,
                    value: item.id,
                };
            });
            setCountryLists(data)
        }).catch((error) => {
            console.log(error)
        })
    }

    const getStateLists = (country_id) => {

        if (country_id) {
            get_state_lists(country_id).then((response) => {
                const data = response?.data?.map((item) => {
                    return {
                        label: item.name,
                        value: item.id,
                    };
                });
                setStateLists(data)
            }).catch((error) => {
                setStateLists([])
                console.log(error)
            })
        } else {
            setStateLists([])
        }

    }
    const getCityLists = (country_id, state_id) => {

        if (country_id && state_id) {
            get_city_lists(country_id, state_id).then((response) => {
                const data = response?.data?.map((item) => {
                    return {
                        label: item.name,
                        value: item.id,
                    };
                });
                setCityLists(data)
            }).catch((error) => {
                setCityLists([])
                console.log(error)
            })
        } else {
            setCityLists([])
        }

    }
    const ResetForm = () => {
        formik.setValues({
            ...formik.values,
            "CI_name": "",
            "CI_statusId": 1,
            "CI_countryid": "",
            "CI_stateId": "",
            "CI_city": "",
            "CI_email": "",
            "CI_phone": "",
            "CI_address": "",
            "CI_pincode": "",
            "CI_Remarks": "",
            "CI_client_type": "",
            "CI_gst_no": "",
            "CI_exampted": null,
            "CI_aadhar_number": "",
            "CI_Pan_No": "",
            "CI_passport_num": "",
            "CI_contract_start": "",
            "CI_contract_end": "",
        });
    }
    return (
        <div className={is_open_modal ? 'clients_form_super_container_modal' : 'clients_form_super_container'}>
            <div >
                {!is_open_modal && (
                    <div className='clients_form_action_sub_container'>
                        <div className='clients_form_single_row_alignment'>
                            <IoArrowBackSharp className='clients_form_back' onClick={() => {
                                navigate(-1)
                            }} />
                            <p className='clients_form_title'>{client_id && isView ? "View" : client_id ? 'Update' : "Add New"} Client</p>
                        </div>
                        {!isView && (
                            <div className='clients_form_single_row_alignment'>
                                {!client_id && (
                                    <button className='clients_form_reset_button' onClick={() => {
                                        ResetForm()
                                    }}>Reset</button>
                                )}

                                <button className='clients_form_save_button'
                                    type="submit"
                                    form="clientsform"
                                >Save</button>
                            </div>)}
                    </div>
                )}


                <div>
                    <form id="clientsform" onSubmit={formik.handleSubmit}>
                        <div className='clients_form_main_container'>
                            <p className="clients_form_legend">Details</p>
                            <div className='clients_form_sub_container_0'>
                                <div>
                                    <p className='clients_form_label'>Client Name<span className='label_required_remark'>*</span></p>
                                    <CustomTextField
                                        autoFocus
                                        isDisabled={isView}
                                        name="CI_name"
                                        placeholder={"Enter client name"}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.CI_name}
                                        error={formik.touched.CI_name &&
                                            Boolean(formik.errors.CI_name)
                                        }
                                        helperText={
                                            formik.touched.CI_name && formik.errors.CI_name
                                        }
                                    />
                                </div>

                                <div>
                                    <p className='clients_form_label'>Email</p>
                                    <CustomTextField
                                        isDisabled={isView}
                                        name="CI_email"
                                        placeholder={"Enter email"}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.CI_email}
                                        error={formik.touched.CI_email &&
                                            Boolean(formik.errors.CI_email)
                                        }
                                        helperText={
                                            formik.touched.CI_email && formik.errors.CI_email
                                        }
                                    />
                                </div>
                                <div>
                                    <p className='clients_form_label'>Phone Number</p>
                                    <CustomTextField
                                        isDisabled={isView}
                                        name="CI_phone"
                                        placeholder={"Enter phone number"}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.CI_phone}
                                        error={formik.touched.CI_phone &&
                                            Boolean(formik.errors.CI_phone)
                                        }
                                        helperText={
                                            formik.touched.CI_phone && formik.errors.CI_phone
                                        }
                                    />
                                </div>

                                <div>
                                    <p className='clients_form_label'>Address</p>
                                    <CustomTextField
                                        isDisabled={isView}
                                        name="CI_address"
                                        placeholder={"Enter address"}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.CI_address}
                                        error={formik.touched.CI_address &&
                                            Boolean(formik.errors.CI_address)
                                        }
                                        helperText={
                                            formik.touched.CI_address && formik.errors.CI_address
                                        }
                                    />
                                </div>
                                <div>
                                    <p className='clients_form_label'>Country</p>
                                    <CustomAutoCompleteDropdown
                                        isDisabled={isView}
                                        name="CI_countryid"
                                        placeholder={"Select a country"}
                                        options={countryLists}
                                        onChange={(event, newValue) => {
                                            formik.setFieldValue(
                                                "CI_countryid",
                                                newValue ? newValue.value : ""
                                            );
                                            getStateLists(newValue?.value)
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={
                                            countryLists.find((country) =>
                                                country.value ===
                                                formik.values.CI_countryid
                                            ) || null
                                        }
                                        error={
                                            formik.touched.CI_countryid &&
                                            Boolean(formik.errors.CI_countryid)
                                        }
                                        helperText={
                                            formik.touched.CI_countryid &&
                                            formik.errors.CI_countryid
                                        }
                                    />
                                </div>
                                <div>
                                    <p className='clients_form_label'>State</p>
                                    <CustomAutoCompleteDropdown
                                        isDisabled={isView}
                                        name="CI_stateId"
                                        placeholder={"Select a state"}
                                        options={stateLists}
                                        onChange={(event, newValue) => {
                                            formik.setFieldValue(
                                                "CI_stateId",
                                                newValue ? newValue.value : ""
                                            );
                                            // getCityLists(formik.values.CI_countryid, newValue?.value)
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={
                                            stateLists.find((state) =>
                                                state.value ===
                                                formik.values.CI_stateId
                                            ) || null
                                        }
                                        error={
                                            formik.touched.CI_stateId &&
                                            Boolean(formik.errors.CI_stateId)
                                        }
                                        helperText={
                                            formik.touched.CI_stateId &&
                                            formik.errors.CI_stateId
                                        }
                                    />
                                </div>
                                <div>
                                    <p className='clients_form_label'>City</p>
                                    <CustomTextField
                                        isDisabled={isView}
                                        name="CI_city"
                                        placeholder={"Enter city"}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.CI_city}
                                        error={formik.touched.CI_city &&
                                            Boolean(formik.errors.CI_city)
                                        }
                                        helperText={
                                            formik.touched.CI_city && formik.errors.CI_city
                                        }
                                    />
                                </div>

                                <div>
                                    <p className='clients_form_label'>Postal Code</p>
                                    <CustomTextField
                                        isDisabled={isView}
                                        name="CI_pincode"
                                        placeholder={"Enter postal code"}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.CI_pincode}
                                        error={formik.touched.CI_pincode &&
                                            Boolean(formik.errors.CI_pincode)
                                        }
                                        helperText={
                                            formik.touched.CI_pincode && formik.errors.CI_pincode
                                        }
                                    />
                                </div>
                                <div>
                                    <p className='clients_form_label'>Status<span className='label_required_remark'>*</span></p>
                                    <CustomAutoCompleteDropdown
                                        isDisabled={isView}
                                        name="CI_statusId"
                                        placeholder={"Select a status"}
                                        options={status}
                                        onChange={(event, newValue) => {
                                            formik.setFieldValue(
                                                "CI_statusId",
                                                newValue ? newValue.value : ""
                                            );
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={
                                            status.find((status) =>
                                                status.value ===
                                                formik.values.CI_statusId
                                            ) || null
                                        }
                                        error={
                                            formik.touched.CI_statusId &&
                                            Boolean(formik.errors.CI_statusId)
                                        }
                                        helperText={
                                            formik.touched.CI_statusId &&
                                            formik.errors.CI_statusId
                                        }
                                    />
                                </div>
                            </div>

                            <div className='clients_form_sub_container_1'>
                                <p className='clients_form_label'>Remarks</p>
                                <CustomTextArea
                                    isDisabled={isView}
                                    rows={6}
                                    name="CI_Remarks"
                                    placeholder={"Enter a remark"}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.CI_Remarks}
                                    error={formik.touched.CI_Remarks &&
                                        Boolean(formik.errors.CI_Remarks)
                                    }
                                    helperText={
                                        formik.touched.CI_Remarks && formik.errors.CI_Remarks
                                    }
                                />
                            </div>

                        </div>
                        <div className='clients_form_main_container'>
                            <p className="clients_form_legend2">Additional Information</p>
                            <div className='clients_form_sub_container_0'>
                                <div>
                                    <p className='clients_form_label'>Aadhar Number</p>
                                    <CustomTextField
                                        isDisabled={isView}
                                        name="CI_aadhar_number"
                                        placeholder={"Enter GST Number"}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.CI_aadhar_number}
                                        error={formik.touched.CI_aadhar_number &&
                                            Boolean(formik.errors.CI_aadhar_number)
                                        }
                                        helperText={
                                            formik.touched.CI_aadhar_number && formik.errors.CI_aadhar_number
                                        }
                                    />
                                </div>
                                <div>
                                    <p className='clients_form_label'>PAN Number</p>
                                    <CustomTextField
                                        isDisabled={isView}
                                        name="CI_Pan_No"
                                        placeholder={"Enter PAN Number"}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.CI_Pan_No}
                                        error={formik.touched.CI_Pan_No &&
                                            Boolean(formik.errors.CI_Pan_No)
                                        }
                                        helperText={
                                            formik.touched.CI_Pan_No && formik.errors.CI_Pan_No
                                        }
                                    />
                                </div>
                                <div>
                                    <p className='clients_form_label'>Passport Number</p>
                                    <CustomTextField
                                        isDisabled={isView}
                                        name="CI_passport_num"
                                        placeholder={"Enter Passport Number"}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.CI_passport_num}
                                        error={formik.touched.CI_passport_num &&
                                            Boolean(formik.errors.CI_passport_num)
                                        }
                                        helperText={
                                            formik.touched.CI_passport_num && formik.errors.CI_passport_num
                                        }
                                    />
                                </div>
                                <div>
                                    <p className='clients_form_label'>Client Type<span className='label_required_remark'>*</span></p>
                                    <CustomAutoCompleteDropdown
                                        isDisabled={isView}
                                        name="CI_client_type"
                                        placeholder={"Select a client type"}
                                        options={clientType}
                                        onChange={(event, newValue) => {
                                            formik.setFieldValue(
                                                "CI_client_type",
                                                newValue ? newValue.value : ""
                                            );
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={
                                            clientType.find((status) =>
                                                status.value ===
                                                formik.values.CI_client_type
                                            ) || null
                                        }
                                        error={
                                            formik.touched.CI_client_type &&
                                            Boolean(formik.errors.CI_client_type)
                                        }
                                        helperText={
                                            formik.touched.CI_client_type &&
                                            formik.errors.CI_client_type
                                        }
                                    />
                                </div>
                                <div>
                                    <p className='clients_form_label'>GST No</p>
                                    <CustomTextField
                                        isDisabled={isView}
                                        name="CI_gst_no"
                                        placeholder={"Enter GST Number"}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.CI_gst_no}
                                        error={formik.touched.CI_gst_no &&
                                            Boolean(formik.errors.CI_gst_no)
                                        }
                                        helperText={
                                            formik.touched.CI_gst_no && formik.errors.CI_gst_no
                                        }
                                    />
                                </div>
                                <div>
                                    <p className='clients_form_label'>Exempted<span className='label_required_remark'>*</span></p>
                                    <CustomAutoCompleteDropdown
                                        isDisabled={isView}
                                        name="CI_exampted"
                                        placeholder={"Select"}
                                        options={Exempted}
                                        onChange={(event, newValue) => {
                                            formik.setFieldValue(
                                                "CI_exampted",
                                                newValue ? newValue.value : ""
                                            );
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={
                                            Exempted.find((status) =>
                                                status.value ===
                                                formik.values.CI_exampted
                                            ) || null
                                        }
                                        error={
                                            formik.touched.CI_exampted &&
                                            Boolean(formik.errors.CI_exampted)
                                        }
                                        helperText={
                                            formik.touched.CI_exampted &&
                                            formik.errors.CI_exampted
                                        }
                                    />
                                </div>
                                <div>
                                    <p className='clients_form_label'>Contract Start Date</p>
                                    <CustomDatePicker
                                        disabled={isView}

                                        name="CI_contract_start"
                                        onChange={(date) => {
                                            formik.setFieldValue("CI_contract_start", date);
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={dayjs(formik.values.CI_contract_start, "DD-MM-YYYY")}
                                        error={
                                            formik.touched.CI_contract_start &&
                                            Boolean(formik.errors.CI_contract_start)
                                        }
                                        helperText={
                                            formik.touched.CI_contract_start && formik.errors.CI_contract_start
                                        } />
                                </div>
                                <div>
                                    <p className='clients_form_label'>Contract End Date</p>
                                    <CustomDatePicker
                                        disabled={isView}
                                        name="CI_contract_end"
                                        onChange={(date) => {
                                            formik.setFieldValue("CI_contract_end", date);
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={dayjs(formik.values.CI_contract_end, "DD-MM-YYYY")}
                                        error={
                                            formik.touched.CI_contract_end &&
                                            Boolean(formik.errors.CI_contract_end)
                                        }
                                        helperText={
                                            formik.touched.CI_contract_end && formik.errors.CI_contract_end
                                        } />
                                </div>
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', width: '100%' }}>
                                <div style={{ flex: '1 1 20%' }}>
                                    {!isView && (
                                        <>
                                            <p className='flight_ticket_additional_info_main_text'>GST Certification</p>
                                            <div style={{ margin: '20px 0px' }}>
                                                <label htmlFor="filesclient" className="flight_ticket_choose_file_field">
                                                    <span id="fileInfoclient">Choose file</span>
                                                </label>
                                                <input
                                                    id="filesclient"
                                                    style={{ display: "none" }}
                                                    type="file"
                                                    onChange={(event) => handleFileSelectClient(event)}
                                                />
                                            </div>
                                        </>
                                    )}
                                    <p className='flight_ticket_additional_info_main_text'>GST Certification</p>
                                    <div className='flight_ticket_additional_info_attchment_main_container'>
                                        {fileAttachments_Client && fileAttachments_Client.length > 0 && fileAttachments_Client.map((item, index) => (
                                            <div className='flight_ticket_additional_info_attchment_item'>
                                                <p>{item.fileName ? item.fileName : item.CGD_file_name}</p>
                                                <div className='flight_ticket_additional_info_attchment_item_action'>
                                                    <RiDownloadLine className='flight_ticket_additional_info_attchment_download' onClick={() => {
                                                        if (item.file_path) {
                                                            CommonHelpers.DownloadAssets(item.file_path)
                                                        } else {
                                                            CommonHelpers.DownloadFileObjectAssets(item.fileName, item.file)
                                                        }
                                                    }} />
                                                    {!isView && (
                                                        <MdDeleteOutline className='flight_ticket_additional_info_attchment_delete' onClick={() => {
                                                            delete_particular_attachment_details_Client(index, item)
                                                        }} />
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                <div style={{ flex: '1 1 20%' }}>
                                    {!isView && (
                                        <>
                                            <p className='flight_ticket_additional_info_main_text'>Passport Upload</p>
                                            <div style={{ margin: '20px 0px' }}>
                                                <label htmlFor="filesPassport" className="flight_ticket_choose_file_field">
                                                    <span id="fileInfoPassport">Choose file</span>
                                                </label>
                                                <input
                                                    id="filesPassport"
                                                    style={{ display: "none" }}
                                                    type="file"
                                                    onChange={(event) => handlePassportSelectClient(event)}
                                                />
                                            </div>
                                        </>
                                    )}
                                    <p className='flight_ticket_additional_info_main_text'>Passport Attachemnts</p>
                                    <div className='flight_ticket_additional_info_attchment_main_container'>
                                        {PassportAttachments_Client && PassportAttachments_Client.length > 0 && PassportAttachments_Client.map((item, index) => (
                                            <div className='flight_ticket_additional_info_attchment_item'>
                                                <p>{item.fileName ? item.fileName : item.CGD_file_name}</p>
                                                <div className='flight_ticket_additional_info_attchment_item_action'>
                                                    <RiDownloadLine className='flight_ticket_additional_info_attchment_download' onClick={() => {
                                                        if (item.file_path) {
                                                            CommonHelpers.DownloadAssets(item.file_path)
                                                        } else {
                                                            CommonHelpers.DownloadFileObjectAssets(item.fileName, item.file)
                                                        }
                                                    }} />
                                                    {!isView && (
                                                        <MdDeleteOutline className='flight_ticket_additional_info_attchment_delete' onClick={() => {
                                                            delete_particular_attachment_details_Client_Passport(index, item)
                                                        }} />
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                <div style={{ flex: '1 1 20%' }}>
                                    {!isView && (
                                        <>
                                            <p className='flight_ticket_additional_info_main_text'>PAN Upload</p>
                                            <div style={{ margin: '20px 0px' }}>
                                                <label htmlFor="filesPan" className="flight_ticket_choose_file_field">
                                                    <span id="fileInfocpan">Choose file</span>
                                                </label>
                                                <input
                                                    id="filesPan"
                                                    style={{ display: "none" }}
                                                    type="file"
                                                    onChange={(event) => handlepanSelectClient(event)}
                                                />
                                            </div>
                                        </>
                                    )}
                                    <p className='flight_ticket_additional_info_main_text'>PAN Attachments</p>
                                    <div className='flight_ticket_additional_info_attchment_main_container'>
                                        {PanAttachments_Client && PanAttachments_Client.length > 0 && PanAttachments_Client.map((item, index) => (
                                            <div className='flight_ticket_additional_info_attchment_item'>
                                                <p>{item.fileName ? item.fileName : item.CGD_file_name}</p>
                                                <div className='flight_ticket_additional_info_attchment_item_action'>
                                                    <RiDownloadLine className='flight_ticket_additional_info_attchment_download' onClick={() => {
                                                        if (item.file_path) {
                                                            CommonHelpers.DownloadAssets(item.file_path)
                                                        } else {
                                                            CommonHelpers.DownloadFileObjectAssets(item.fileName, item.file)
                                                        }
                                                    }} />
                                                    {!isView && (
                                                        <MdDeleteOutline className='flight_ticket_additional_info_attchment_delete' onClick={() => {
                                                            delete_particular_attachment_details_Client_Pan(index, item)
                                                        }} />
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                <div style={{ flex: '1 1 20%' }}>
                                    {!isView && (
                                        <>
                                            <p className='flight_ticket_additional_info_main_text'>Aadhar Upload</p>
                                            <div style={{ margin: '20px 0px' }}>
                                                <label htmlFor="fileInfoclient_aadhar" className="flight_ticket_choose_file_field">
                                                    <span id="fileInfoclient">Choose file</span>
                                                </label>
                                                <input
                                                    id="fileInfoclient_aadhar"
                                                    style={{ display: "none" }}
                                                    type="file"
                                                    onChange={(event) => handleAadharSelectClient(event)}
                                                />
                                            </div>
                                        </>
                                    )}
                                    <p className='flight_ticket_additional_info_main_text'>Attachments</p>
                                    <div className='flight_ticket_additional_info_attchment_main_container'>
                                        {AadharAttachments_Client && AadharAttachments_Client.length > 0 && AadharAttachments_Client.map((item, index) => (
                                            <div className='flight_ticket_additional_info_attchment_item'>
                                                <p>{item.fileName ? item.fileName : item.CGD_file_name}</p>
                                                <div className='flight_ticket_additional_info_attchment_item_action'>
                                                    <RiDownloadLine className='flight_ticket_additional_info_attchment_download' onClick={() => {
                                                        if (item.file_path) {
                                                            CommonHelpers.DownloadAssets(item.file_path)
                                                        } else {
                                                            CommonHelpers.DownloadFileObjectAssets(item.fileName, item.file)
                                                        }
                                                    }} />
                                                    {!isView && (
                                                        <MdDeleteOutline className='flight_ticket_additional_info_attchment_delete' onClick={() => {
                                                            delete_particular_attachment_details_Client_Aadhar(index, item)
                                                        }} />
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div style={{ flex: '1 1 20%' }}>
                                    {!isView && (
                                        <>
                                            <p className='flight_ticket_additional_info_main_text'>Contract Document(If any)</p>
                                            <div style={{ margin: '20px 0px' }}>
                                                <label htmlFor="filescontract" className="flight_ticket_choose_file_field">
                                                    <span id="fileInfocontract">Choose file</span>
                                                </label>
                                                <input
                                                    id="filescontract"
                                                    style={{ display: "none" }}
                                                    type="file"
                                                    onChange={(event) => handleContractSelectClient(event)}
                                                />
                                            </div>
                                        </>
                                    )}
                                    <p className='flight_ticket_additional_info_main_text'>Contract Attachments</p>
                                    <div className='flight_ticket_additional_info_attchment_main_container'>
                                        {ContractAttachments_Client && ContractAttachments_Client.length > 0 && ContractAttachments_Client.map((item, index) => (
                                            <div className='flight_ticket_additional_info_attchment_item'>
                                                <p>{item.fileName ? item.fileName : item.CGD_file_name}</p>
                                                <div className='flight_ticket_additional_info_attchment_item_action'>
                                                    <RiDownloadLine className='flight_ticket_additional_info_attchment_download' onClick={() => {
                                                        if (item.file_path) {
                                                            CommonHelpers.DownloadAssets(item.file_path)
                                                        } else {
                                                            CommonHelpers.DownloadFileObjectAssets(item.fileName, item.file)
                                                        }
                                                    }} />
                                                    {!isView && (
                                                        <MdDeleteOutline className='flight_ticket_additional_info_attchment_delete' onClick={() => {
                                                            delete_particular_attachment_details_Client_Contract(index, item)
                                                        }} />
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </form>

                </div>
                {is_open_modal && (
                    <div>
                        <div className='clients_form_single_row_alignment_modal'>
                            {!client_id && (
                                <button className='clients_form_reset_button' onClick={() => {
                                    close_modal(false)
                                }}>Cancel</button>
                            )}

                            <button className='clients_form_save_button'
                                type="submit"
                                form="clientsform"
                            >Save</button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default ClientForm
