import React, { useState } from 'react'
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { Table } from "@mui/material";
import table_no_data from '../../assets/table_not_data.png'
import './customTableStyle.css'
function CustomTable({ columns, rows, no_border, table_legend, selectedRow ,isMoveOver}) {
  const [onMouseOver, setOnMouseOver] = useState(null)
  const StyledTableCell = styled(TableCell)(({ isAction }) => ({
    ...(isAction && {
      textAlign: 'center',
    }),
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#38818133",
      color: "#000000",
      borderBottom: "none",
      fontWeight: "600",
      fontFamily: "Jost",
      fontSize: "14px",
      padding: "15px 12px",

    },
    [`&.${tableCellClasses.body}`]: {
      maxWidth: "500px",
      overflowWrap: "break-word",
      fontSize: "14px",
      borderBottom: "none",
      fontFamily: "Jost",
      padding: "12px 15px",
      fontWeight: "400",
      overflow: "auto",
      whiteSpace: "normal",
      wordWrap: "break-word",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#ffffff",
      "& > *:first-of-type": {
        backgroundColor: "#ffffff",
      },
    },
    "&:nth-of-type(even)": {
      backgroundColor: "#f2f6f6",
      "& > *:first-of-type": {
        backgroundColor: "#f2f6f6",
      },
    },
  }));
  const StyledTableRowWithBorderRadius = styled(TableRow)(({ theme }) => ({
    '&:first-of-type th:first-of-type': {
      borderTopLeftRadius: no_border ? '0px' : '10px',
      borderBottomLeftRadius: no_border ? '0px' : '10px',
    },
    '&:first-of-type th:last-of-type': {
      textAlign: columns.length >= 5 ? 'left' : 'center',
      borderTopRightRadius: no_border ? '0px' : '10px',
      borderBottomRightRadius: no_border ? '0px' : '10px',
    },
  }));
  return (
    <>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead style={{
            borderRadius: "20px",
            //  whiteSpace:'nowrap'
          }}>
            <StyledTableRowWithBorderRadius>
              {columns &&
                columns.map((column) => (
                  <StyledTableCell
                    key={column.key}
                    isAction={!!column.actions}
                  >
                    {column.title}
                  </StyledTableCell>
                ))}
            </StyledTableRowWithBorderRadius>
          </TableHead>
          <TableBody>
            {rows && rows.map((row) => (
              <>
                <StyledTableRow key={row.key} style={{ cursor: row.is_row_select || row.isMoveOver ? 'pointer' : 'default', 'position': 'relative' }} onMouseLeave={()=>{
                  if(row.isMoveOver){
                    setOnMouseOver(null)
                  }
                }} onMouseOver={() => {
                  if(row.isMoveOver){
                    setOnMouseOver(row.key)
                  }
                }} onClick={() => {
                  if (selectedRow && row.is_row_select) {
                    selectedRow(row)
                  }
                }}>
                  {columns.map((column) => (
                    <StyledTableCell
                      key={column.key}
                      style={{ color: row.is_highlight_row ? row.highlight_color : '' }}
                    >
                      {column.action ? (
                        column.action(row)
                      ) : column.wrapper ? (
                        column.wrapper(row)
                      ) : (
                        <div>
                          <p>
                            {row[column.dataIndex] == 0 || row[column.dataIndex] ? row[column.dataIndex] : " - "}
                          </p>
                        </div>
                      )}
                    </StyledTableCell>
                  ))}

                </StyledTableRow>

                { isMoveOver && row.isMoveOver && onMouseOver && onMouseOver == row.key && (
                  <div className='custom_table_mouse_over_popup'>
                    <div className='custom_table_mouse_over_popup_sub_text_container'>
                       <p className='custom_table_mouse_over_popup_sub_text_label'>Cancellation Reason : </p>
                       <p>&nbsp;{row.cancellation_reason?row.cancellation_reason:'-'}</p>
                    </div>
                    <div  className='custom_table_mouse_over_popup_sub_text_container'>
                       <p className='custom_table_mouse_over_popup_sub_text_label'>Cancellation Comment : </p>
                       <p>&nbsp;{row.cancellation_comment?row.cancellation_comment:'-'}</p>
                    </div>
                  </div>
                )}

              </>
            ))}
          </TableBody>
          {table_legend}
        </Table>

      </TableContainer>
      {rows && rows.length === 0 && (
        <div className="no_data_container">
          <div className="no_data_sub_container">
            {/* <img src={table_no_data} className='no_data_image' /> */}
            <p>No data available</p>
          </div>
        </div>
      )}
    </>

  )
}

export default CustomTable