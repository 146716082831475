import { createSlice } from "@reduxjs/toolkit";

export const commonSlice=createSlice({
    name:"common",
    initialState:{
        isCollapsed:false,
        privilage_Details:null
    },
    reducers:{
        updateCollapsedState:(state,actions)=>{
            state.isCollapsed=!state.isCollapsed
        },
        updatePrivilageDetails:(state,actions)=>{
            state.privilage_Details=!state.privilage_Details
        }
    }
})

export const {updateCollapsedState,updatePrivilageDetails}=commonSlice.actions

export default commonSlice.reducer