import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from "yup";
import CustomTextField from '../../../../../components/custom_text_field/CustomTextField';
import { IoArrowBackSharp } from "react-icons/io5";
import dayjs from "dayjs";
import CustomDatePicker from '../../../../../components/custom_date_picker/CustomDatePicker';
import CustomAutoCompleteDropdown from '../../../../../components/custom_auto_complete_dropdown/CustomAutoCompleteDropdown';
import { get_supplier_without_pagination } from '../../../../../api/supplierApis';
import { ErrorToast, SuccessToast } from '../../../../../toast/toasts';
import { CgAddR } from "react-icons/cg";
import { Chip, IconButton, ListItem, Paper } from '@mui/material';
import { IoIosCloseCircleOutline } from "react-icons/io";
import './PackagesFormStyle.css'
import PackagesAdditionalInfo from '../packages_additional_info/PackagesAdditionalInfo';
import CustomTextArea from '../../../../../components/custom_text_area/CustomTextArea';
import { get_status } from '../../../../../api/statusApis';
import { get_client_lists_without_pagination } from '../../../../../api/clientApis';
import CustomModal from '../../../../../components/custom_modal/CustomModal';
import SupplierForm from '../../../master_settings/suppliers/supplier_form/SupplierForm';
import ClientForm from '../../../master_settings/clients/client_form/ClientForm';
import HotelForm from '../../hotel_or_resorts/hotel_form/HotelForm';
import { get_hotels } from '../../../../../api/hotelApis';
import PackagesSupplierTable from '../packages_spplier_table/PackagesSupplierTable';
import { create_new_package, get_package_details_by_id, update_new_package } from '../../../../../api/packageApis';
import { get_services } from '../../../../../api/servicesApis';

function PackagesForm() {
  const navigate = useNavigate()
  let { booking_id } = useParams();
  const location = useLocation()


  const [isView, setIsView] = useState(null)
  const [isAmendment, setIsAmendment] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false) //true when update is enable

  const [status, setstatus] = useState([])

  const [client, setClient] = useState([])
  const [hotels, setHotels] = useState([])

  const [client_creation_modal, set_client_creation_modal] = useState(false)
  const [supplier_creation_modal, set_supplier_creation_modal] = useState(false)
  const [hotel_creation_modal, set_hotel_creation_modal] = useState(false)


  const [deletedAttachments, setDeletedAttachments] = useState([])
  const [fileAttachments, setFileAttachments] = useState([])
  const [isSaveAsDraft, setIsSaveAsDraft] = useState(true)

  const [passengerCount, setPassengerCount] = useState(0)
  const [passengerList, setPassengerList] = useState([])

  const [isUpdated, setIsUpdated] = useState(false)

  const [servicesList, setServicesList] = useState([])

  const [passengerOtherCount, setPassengerOtherCount] = useState({
    'adult': 0,
    'child': 0,
    'infants': 0
  })
  const [isDraft, setIsDraft] = useState(false);

  const [chipData, setChipData] = useState([]);
  const [pnr, setPnr] = useState('');

  const getHotels = () => {
    get_hotels().then((response) => {
      const data = response?.data?.map((item) => {
        return {
          label: item.hotelName,
          value: item.hotel_id,
        };
      });
      setHotels(data)
    }).catch((error) => {
      console.log(error)
    })
  }

  const getStatus = () => {
    get_status().then((response) => {
      const data = response.map((item) => {
        return {
          label: item.status_name,
          value: item.id,
        };
      });
      setstatus(data)
    }).catch((error) => {
      console.log(error)
    })
  }

  const getServices = () => {
    get_services().then((response) => {
      const data = response.map((item) => {
        return {
          label: item.ES_services_name,
          value: item.ES_id,
        };
      });
      setServicesList(data)
    }).catch((error) => {
      console.log(error)
    })
  }
  const getClientList = () => {
    get_client_lists_without_pagination().then((response) => {
      const data = response?.data?.map((item) => {
        return {
          label: item.CI_name,
          value: item.CI_id,
        };
      });
      setClient(data)
    }).catch((error) => {
      console.log(error)
    })
  }

  useEffect(() => {
    if (location.pathname.split('/').pop() === "view_package") {
      setIsView(true);
    } else if (location.pathname.split('/').pop() === "update_package") {
      setIsView(false);
      setIsUpdate(true);
    }

    if (booking_id) {
      const payload = {
        booking_id
      }
      get_package_details_by_id(payload)
        .then((response) => {
          const bookingData = response?.data?.data
          const passengerData = bookingData?.suppliers;
          // setIsSaveAsDraft(bookingData?.status_id === 1 ? true : false)
          setIsDraft(bookingData?.status_id === 1 ? true : false)
          // const passengerDocuments = response?.data?.documents;



          formik.setValues({
            ...formik.values,
            filename: bookingData.filename,
            issueDate: bookingData.issueDate ? bookingData.issueDate.split('T')[0] : null,
            hotel: bookingData.hotel_id,
            designation: bookingData.designation,
            checkIn: bookingData.checkIn ? bookingData.checkIn.split('T')[0] : null,
            checkOut: bookingData.checkOut ? bookingData.checkOut.split('T')[0] : null,
            adult: bookingData.adult,
            child: bookingData.child,
            infant: bookingData.infant,
            client: bookingData.client_id,
            client_amount: bookingData.client_amount,
            // service: bookingData.service_id,
            service_fee: bookingData.service_fee,
            gst: bookingData?.gst || "",
            tcs: bookingData?.tcs || "",
            // attachment: bookingData.attachment,
            narration: bookingData?.narration || "",
            reference: bookingData?.reference || "",
            comments: bookingData?.comments || "",


          });
          setPassengerList(passengerData);

          const parsedPnrs = JSON.parse(bookingData?.pnr_refernce || '[]');
          setChipData(parsedPnrs.map((item, index) => ({ key: index + 1, label: item })));

          if (bookingData?.attachments === null) {
            setFileAttachments([]);
          } else {
            setFileAttachments(bookingData?.attachments.map((item, index) => ({ url: item.attachment, fileName: item.name })));
          }
          //OLD
          // if (bookingData?.attchemt_url === null, bookingData?.attachment === null) {
          //   setFileAttachments([]);
          // } else {
          //   setFileAttachments([{ url: bookingData?.attchemt_url, fileName: bookingData?.attachment }]);
          // }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [isUpdated, booking_id]);


  useEffect(() => {
    getStatus()
    getClientList()
    getServices()
  }, [])

  useEffect(() => {
    getHotels()
  }, [hotel_creation_modal])


  const handleAdd = () => {
    if (pnr.trim() !== '') {
      setChipData((chips) => [
        ...chips,
        { key: chips.length + 1, label: pnr },
      ]);
      setPnr(''); // Clear input field
      formik.setFieldValue("pnr_nos", '');
    }
  };


  const handleDelete = (chipToDelete) => () => {
    setChipData((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
  };

  // const handleDelete = (indexToDelete) => () => {
  //   setChipData((chips) => chips.filter((_, index) => index !== indexToDelete));
  // };
  const handleClientCreationModal = (is_close) => {
    set_client_creation_modal(is_close);
  };
  const handleSupplierCreationModal = (is_close) => {
    set_supplier_creation_modal(is_close);
  };

  //for hotel creation modal handle
  const handleHotelCreationModal = (is_close) => {
    set_hotel_creation_modal(is_close);
  };

  const set_attachment_list = (item) => {
    // Set only a single file as the attachment ?.file
    // setFileAttachments([item]);

    setFileAttachments([...fileAttachments, item])

  }

  const delete_particular_attachment_details = (index, item) => {
    fileAttachments?.splice(index, 1)
    setFileAttachments([...fileAttachments])
    if (item.FBDF_id) {
      setDeletedAttachments([...deletedAttachments, item.FBDF_id])
    }
  }

  // console.log("fileAttachments", fileAttachments);

  //NEW SUBMIT FUNCTION
  const formik = useFormik({
    initialValues: {
      filename: '',
      issueDate: null,
      hotel: '',
      designation: '',
      checkIn: null,
      checkOut: null,
      adult: 0,
      child: 0,
      infant: 0,
      client: '',
      client_amount: 0,
      // service: '',
      service_fee: 0,
      gst: 0,
      tcs: 0,
    },
    validationSchema: Yup.object({
      filename: Yup.string().required('File name is required'),
      designation: Yup.string().required('Designation is required'),
      issueDate: Yup.string().required("Issue date is required"),
      checkIn: Yup.string().required("Check in date is required"),
      checkOut: Yup.string().required("Check out date is required"),
      adult: Yup.number()
        .required("Adult count is required")
        .min(0, "Adult count cannot be less than 0"),
      child: Yup.number()
        .required("Child count is required")
        .min(0, "Child count cannot be less than 0"),
      infant: Yup.number()
        .required("Infant count is required")
        .min(0, "Infant count cannot be less than 0"),
      service_fee: Yup.number().min(0, 'Must be greater than or equal to 0')
        .required('Service fee is required'),
      client_amount: Yup.number().min(0, 'Must be greater than or equal to 0')
        .required('Client amount is required'),
      // service: Yup.string().required("Service is required"),
      client: Yup.string().required("Client is required"),
      hotel: Yup.string().required("Hotel is required"),
    }),
    onSubmit: (values) => {
      const formData = new FormData();

      if (passengerList.length === 0) {
        return ErrorToast('At least 1 Supplier is required');
      }

      let value = JSON.parse(JSON.stringify(values));
      value.suppliers = JSON.stringify(passengerList);
      // value.status = isSaveAsDraft ? 1 : 2;
      value.status = isDraft ? 1 : 2;

      // Check if the value is undefined or null, and if so, assign an empty string|| ""
      for (const key in values) {
        formData.append(key, values[key]);
      }
      // Append suppliers, status, 
      formData.append("suppliers", value.suppliers);
      formData.append("status", value.status);

      //FOR MULTIPLE IMGES FOR SUPPLIERS
      // if (passengerList[0]?.file?.length > 0) {
      //   passengerList[0]?.file.forEach((fileAttachment, index) => {
      //     formData.append(`supplier_attachments[${index}]`, fileAttachment.file);
      //   });
      // }
      const pnr_values = chipData?.map((item) => item?.label)
      formData.append("pnr_refernce", JSON.stringify(pnr_values));


      // if (fileAttachments?.length > 0) {
      //   fileAttachments.forEach((fileAttachment, index) => {
      //     formData.append(`file[${index}]`, fileAttachment.file);
      //   });
      // }

      if (booking_id) {
        value.booking_id = booking_id;
        formData.append("booking_id", value.booking_id);

        if (fileAttachments?.length > 0) {
          fileAttachments.forEach((fileAttachment, index) => {
            if (fileAttachment?.file) {
              // Append the file to formData with the file key
              formData.append(`file[${index}]`, fileAttachment.file);
            } else {
              formData.append(`file[${index}][fileNAME]`, fileAttachment?.fileName || '');
            }
            // formData.append(`file[${index}]`, fileAttachment?.file ? fileAttachment?.file : {fileName:{fileAttachment?.fileName}});
          });
        }

        update_new_package(formData).then((response) => {
          if (response?.data?.code === 200) {
            navigate(-1);
            SuccessToast(response.data.message);
            setIsUpdated(!isUpdated);
          } else {
            ErrorToast(response.data.message);
          }
        }).catch((error) => {
          console.log("Error in form submission:", error);
          ErrorToast(`Something Went Wrong`);
        });
      } else {

        if (fileAttachments?.length > 0) {
          fileAttachments.forEach((fileAttachment, index) => {
            formData.append(`file[${index}]`, fileAttachment.file);
          });
        }
        create_new_package(formData).then((response) => {
          // create_new_package(value).then((response) => {
          if (response?.data?.code === 200) {
            navigate(-1);
            SuccessToast(response.data.message);
            setIsUpdated(!isUpdated);
          } else {
            ErrorToast(response.data.message);
          }
        }).catch((error) => {
          console.log("Error in form submission:", error);
          ErrorToast(`Something Went Wrong`);
        });
      }
    }
  });
  const set_passenger_list = (item) => {
    setPassengerList([...passengerList, item])
  }
  const update_passenger_list = (item) => {
    passengerList?.splice(item.index, 1, item)
    setPassengerList([...passengerList])
  }
  const delete_particular_passenger_details = (index) => {
    passengerList?.splice(index, 1)
    setPassengerList([...passengerList])
  }

  const resetState = () => {
    setPassengerList([])
    setFileAttachments([])
    setPassengerCount(0)
    setDeletedAttachments([])
  }

  // Get privileges details from local storage
  const getPrivilegesDetails = () => JSON.parse(localStorage.getItem("privileges_details") || "[]");

  // Check if a specific privilege exists in User Management -> User
  const hasPrivilege = (privilegeName) =>
    getPrivilegesDetails().some(
      (item) =>
        item.module_name === "Process ECR" &&
        item.submodules?.["Packages"]?.privileges?.some((priv) => priv.name === privilegeName)
    );

  const Is_Package_Draft = hasPrivilege("Draft");
  const Is_Package_Submit = hasPrivilege("Submit");

  return (
    <div className='packages_form_super_container'>
      <div >
        <div className='packages_form_sub_container'>
          <div className='packages_form_single_row_alignment'>
            <IoArrowBackSharp className='packages_form_back' onClick={() => {
              navigate(-1)
            }} />
            <p className='flight_ticket_form_title'>{booking_id && (booking_id && (isView && !isUpdate)) ? 'View Package Details' : booking_id ? 'Update Package' : 'Book Package'}</p>
          </div>
        </div>

        <div>
          <form id="packagesForm" onSubmit={formik.handleSubmit}>

            <div className='packages_form_main_container'>
              <p className="packages_form_legend">General Info</p>
              <div className='packages_form_sub_container_0'>

                <div>
                  <p className='packages_form_label'>File Name<span className='label_required_remark'>*</span></p>
                  <CustomTextField
                    autoFocus
                    isDisabled={booking_id && (isView && !isUpdate)}
                    name="filename"
                    placeholder={"Enter File name"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.filename}
                    error={formik.touched.filename && Boolean(formik.errors.filename)}
                    helperText={formik.touched.filename && formik.errors.filename}
                  />
                </div>

                <div>
                  <p className='flight_ticket_form_label'>Issue Date<span className='label_required_remark'>*</span></p>
                  <div style={{ width: "100%" }}>
                    <CustomDatePicker
                      disabled={booking_id && (isView && !isUpdate)}
                      name="issueDate"
                      onChange={(date) => {
                        formik.setFieldValue("issueDate", date);
                      }}
                      onBlur={formik.handleBlur}
                      value={dayjs(formik.values.issueDate, "DD-MM-YYYY")}
                      error={
                        formik.touched.issueDate &&
                        Boolean(formik.errors.issueDate)
                      }
                      helperText={
                        formik.touched.issueDate && formik.errors.issueDate
                      } />

                  </div>

                </div>
                <div>
                  <div className='packages_form_label_with_action_container'>
                    <p className='packages_form_label_with_action'>Hotel<span className='label_required_remark'>*</span></p>
                    {!isView && (
                      <CgAddR className='packages_booking_label_action' onClick={() => {
                        handleHotelCreationModal(true)
                      }} />
                    )}
                  </div>
                  <CustomAutoCompleteDropdown
                    isDisabled={booking_id && (isView && !isUpdate)}
                    name="hotel"
                    placeholder={"Select Hotel "}
                    options={hotels}
                    onChange={(event, newValue) => {
                      formik.setFieldValue(
                        "hotel",
                        newValue ? newValue.value : ""
                      );
                    }}
                    onBlur={formik.handleBlur}
                    value={
                      hotels.find((type) =>
                        type.value ===
                        formik.values.hotel
                      ) || null
                    }
                    error={
                      formik.touched.hotel &&
                      Boolean(formik.errors.hotel)
                    }
                    helperText={
                      formik.touched.hotel &&
                      formik.errors.hotel
                    }
                  />
                </div>
                <div>

                  <p className='packages_form_label'>Designation <span className='label_required_remark'>*</span></p>
                  <CustomTextField
                    isDisabled={booking_id && (isView && !isUpdate)}
                    name="designation"
                    placeholder={"Enter designation "}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.designation}
                    error={formik.touched.designation && Boolean(formik.errors.designation)}
                    helperText={formik.touched.designation && formik.errors.designation}
                  />
                </div>

                <div>
                  <p className='flight_ticket_form_label'>Check In <span className='label_required_remark'>*</span></p>
                  <div style={{ width: "100%" }}>
                    <CustomDatePicker
                      disabled={booking_id && (isView && !isUpdate)}
                      name="checkIn"
                      onChange={(date) => {
                        formik.setFieldValue("checkIn", date);
                      }}
                      onBlur={formik.handleBlur}
                      value={dayjs(formik.values.checkIn, "DD-MM-YYYY")}
                      error={
                        formik.touched.checkIn &&
                        Boolean(formik.errors.checkIn)
                      }
                      helperText={
                        formik.touched.checkIn && formik.errors.checkIn
                      } />

                  </div>

                </div>

                <div>
                  <p className='packages_form_label'>Check Out <span className='label_required_remark'>*</span></p>
                  <div style={{ width: "100%" }}>
                    <CustomDatePicker
                      disabled={booking_id && (isView && !isUpdate)}
                      name="checkOut"
                      onChange={(date) => {
                        formik.setFieldValue("checkOut", date);
                      }}
                      onBlur={formik.handleBlur}
                      value={dayjs(formik.values.checkOut, "DD-MM-YYYY")}
                      error={
                        formik.touched.checkOut &&
                        Boolean(formik.errors.checkOut)
                      }
                      helperText={
                        formik.touched.checkOut && formik.errors.checkOut
                      } />

                  </div>

                </div>
                <div>
                  <p className='flight_ticket_form_label'>Adult</p>
                  <CustomTextField
                    type="number"
                    isDisabled={booking_id && (isView && !isUpdate)}
                    name="adult"
                    placeholder="Enter adult number"
                    onChange={(e) => {
                      formik.handleChange(e)
                      if (e.target.value !== '') {
                        const amount = Number(e.target.value)
                        formik.setFieldValue("adult", amount);
                        setPassengerCount(amount)
                        setPassengerOtherCount({ ...passengerOtherCount, ['adult']: Number(e.target.value) })
                      }
                    }}
                    // onChange={(e) => {
                    //   formik.handleChange(e);
                    //   const amount = e.target.value ? Number(e.target.value) : 0;
                    //   formik.setFieldValue("adult", amount);
                    //   setPassengerOtherCount({
                    //     ...passengerOtherCount,
                    //     adult: amount,
                    //   });
                    // }}
                    onBlur={formik.handleBlur}
                    value={formik.values.adult}
                    error={formik.touched.adult && Boolean(formik.errors.adult)}
                    inputProps={{ min: 0 }}
                    helperText={formik.touched.adult && formik.errors.adult}
                  />
                </div>

                <div>
                  <p className='flight_ticket_form_label'>Child</p>
                  <CustomTextField
                    type="number"
                    isDisabled={booking_id && (isView && !isUpdate)}
                    name="child"
                    placeholder="Enter child number"
                    onChange={(e) => {
                      formik.handleChange(e)
                      if (e.target.value !== '') {
                        const amount = Number(e.target.value)
                        formik.setFieldValue("child", amount);
                        setPassengerCount(amount)
                        setPassengerOtherCount({ ...passengerOtherCount, ['child']: Number(e.target.value) })
                      }
                    }}
                    // onChange={(e) => {
                    //   formik.handleChange(e);
                    //   const amount = e.target.value ? Number(e.target.value) : 0;
                    //   formik.setFieldValue("child", amount);
                    //   setPassengerOtherCount({
                    //     ...passengerOtherCount,
                    //     child: amount,
                    //   });
                    // }}
                    onBlur={formik.handleBlur}
                    value={formik.values.child}
                    error={formik.touched.child && Boolean(formik.errors.child)}
                    inputProps={{ min: 0 }}
                    helperText={formik.touched.child && formik.errors.child}
                  />
                </div>

                <div>
                  <p className='flight_ticket_form_label'>Infants</p>
                  <CustomTextField
                    type="number"
                    isDisabled={booking_id && (isView && !isUpdate)}
                    name="infant"
                    placeholder="Enter infants number"
                    onChange={(e) => {
                      formik.handleChange(e)
                      if (e.target.value !== '') {
                        const amount = Number(e.target.value)
                        formik.setFieldValue("infant", amount);
                        setPassengerCount(amount)
                        setPassengerOtherCount({ ...passengerOtherCount, ['infant']: Number(e.target.value) })
                      }
                    }}

                    // onChange={(e) => {
                    //   formik.handleChange(e);
                    //   const amount = e.target.value ? Number(e.target.value) : 0;
                    //   formik.setFieldValue("infant", amount);
                    //   setPassengerOtherCount({
                    //     ...passengerOtherCount,
                    //     infant: amount,
                    //   });
                    // }}
                    onBlur={formik.handleBlur}
                    value={formik.values.infant}
                    error={formik.touched.infant && Boolean(formik.errors.infant)}
                    inputProps={{ min: 0 }}
                    helperText={formik.touched.infant && formik.errors.infant}
                  />
                </div>

                {/* <div>
                  <p className='flight_ticket_form_label'>Adult</p>
                  <CustomTextField
                    type={'number'}
                    isDisabled={booking_id && (isView && !isUpdate)}
                    name="adult"
                    placeholder={"Enter adult number"}
                    onChange={(e) => {
                      formik.handleChange(e)
                      if (e.target.value !== '') {
                        const amount = Number(e.target.value)
                        formik.setFieldValue("adult", amount);
                        // setPassengerCount(amount)
                        setPassengerOtherCount({ ...passengerOtherCount, ['adult']: Number(e.target.value) })
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.adult}
                    error={formik.touched.adult &&
                      Boolean(formik.errors.adult)
                    }
                    inputProps={{ inputProps: { min: 0 } }}
                    helperText={
                      formik.touched.adult && formik.errors.adult
                    }
                  />
                </div>

                <div>
                  <p className='flight_ticket_form_label'>Child</p>
                  <CustomTextField
                    type={'number'}
                    isDisabled={booking_id && (isView && !isUpdate)}
                    name="child"
                    placeholder={"Enter child number"}
                    onChange={(e) => {
                      formik.handleChange(e)
                      if (e.target.value !== '') {
                        const amount = Number(e.target.value) + formik.values.adult +
                          formik.values.infant
                        formik.setFieldValue("child", amount);
                        // setPassengerCount(amount)
                        setPassengerOtherCount({ ...passengerOtherCount, ['child']: Number(e.target.value) })
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.child}
                    error={formik.touched.child &&
                      Boolean(formik.errors.child)
                    }
                    inputProps={{ inputProps: { min: 0 } }}
                    helperText={
                      formik.touched.child && formik.errors.child
                    }
                  />
                </div>

                <div>
                  <p className='flight_ticket_form_label'>Infants</p>
                  <CustomTextField
                    type={'number'}
                    isDisabled={booking_id && (isView && !isUpdate)}
                    name="infant"
                    placeholder={"Enter infants number"}
                    onChange={(e) => {
                      formik.handleChange(e)
                      if (e.target.value !== '') {
                        const amount = Number(e.target.value) + formik.values.adult +
                          formik.values.child
                        formik.setFieldValue("infant", amount);
                        // setPassengerCount(amount)
                        setPassengerOtherCount({ ...passengerOtherCount, ['infants']: Number(e.target.value) })
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.infant}
                    error={formik.touched.infant &&
                      Boolean(formik.errors.infant)
                    }
                    inputProps={{ inputProps: { min: 0 } }}
                    helperText={
                      formik.touched.infant && formik.errors.infant
                    }
                  />
                </div> */}
              </div>
            </div>

            <div className='flight_ticket_form_main_container'>
              <p className="flight_ticket_form_legend">Fair </p>
              <div className='packages_form_sub_container_0'>

                {/* <div>
                  <div className='packages_form_label_with_action_container'>
                    <p className='packages_form_label_with_action'>Supplier</p>
                    {!booking_id && (isView && isUpdate) && (
                      <CgAddR className='packages_booking_label_action' onClick={() => {
                        handleSupplierCreationModal(true)
                      }} />
                    )}
                  </div>
                  <CustomAutoCompleteDropdown
                    name="FBD_supplier_id"
                    placeholder={"Select a supplier"}
                    options={supplier}
                    onChange={(event, newValue) => {
                      formik.setFieldValue(
                        "FBD_supplier_id",
                        newValue ? newValue.value : ""
                      );
                    }}
                    onBlur={formik.handleBlur}
                    value={
                      supplier.find((type) =>
                        type.value ===
                        formik.values.FBD_supplier_id
                      ) || null
                    }
                    error={
                      formik.touched.FBD_supplier_id &&
                      Boolean(formik.errors.FBD_supplier_id)
                    }
                    helperText={
                      formik.touched.FBD_supplier_id &&
                      formik.errors.FBD_supplier_id
                    }
                  />
                </div> */}

                <div>
                  <div className='flight_ticket_form_label_with_action_container'>
                    <p className='flight_ticket_form_label_with_action'>Customer/Client<span className='label_required_remark'>*</span></p>
                    {!isView && (
                      <CgAddR className='flight_ticket_booking_label_action' onClick={() => {
                        set_client_creation_modal(true)
                      }} />
                    )}
                  </div>

                  <CustomAutoCompleteDropdown
                    isDisabled={booking_id && (isView && !isUpdate)}
                    name="client"
                    placeholder={"Select a customer"}
                    options={client}
                    onChange={(event, newValue) => {
                      formik.setFieldValue(
                        "client",
                        newValue ? newValue.value : ""
                      );
                    }}
                    onBlur={formik.handleBlur}
                    value={
                      client.find((type) =>
                        type.value ===
                        formik.values.client
                      ) || null
                    }
                    error={
                      formik.touched.client &&
                      Boolean(formik.errors.client)
                    }
                    helperText={
                      formik.touched.client &&
                      formik.errors.client
                    }
                  />
                </div>

                <div>
                  <p className='flight_ticket_form_label'>Client Amount</p>
                  <CustomTextField
                    type={'number'}
                    isDisabled={booking_id && (isView && !isUpdate)}
                    name="client_amount"
                    placeholder={"Enter client amount "}
                    onChange={(e) => {
                      formik.handleChange(e)
                      if (e.target.value !== '') {
                        const amount = Number(e.target.value)
                        formik.setFieldValue("client_amount", amount);
                        // setClientAmount(amount)
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.client_amount}
                    error={formik.touched.client_amount &&
                      Boolean(formik.errors.client_amount)
                    }
                    inputProps={{ inputProps: { min: 0, step: ".01" } }}
                    helperText={
                      formik.touched.client_amount && formik.errors.client_amount
                    }
                  />
                </div>

                {/* <div>
                  <div className='flight_ticket_form_label_with_action_container'>
                    <p className='flight_ticket_form_label_with_action'>Service</p>

                  </div>

                  <CustomAutoCompleteDropdown
                    isDisabled={booking_id && (isView && !isUpdate)}
                    name="service"
                    placeholder={"Select a Service"}
                    options={servicesList}
                    onChange={(event, newValue) => {
                      formik.setFieldValue(
                        "service",
                        newValue ? newValue.value : ""
                      );
                    }}
                    onBlur={formik.handleBlur}
                    value={
                      servicesList?.find((type) =>
                        type.value ===
                        formik.values.service
                      ) || null
                    }
                    error={
                      formik.touched.service &&
                      Boolean(formik.errors.service)
                    }
                    helperText={
                      formik.touched.service &&
                      formik.errors.service
                    }
                  />
                </div> */}

                <div>
                  <p className='flight_ticket_form_label'>Service Fee</p>
                  <CustomTextField
                    type={'number'}
                    isDisabled={booking_id && (isView && !isUpdate)}
                    name="service_fee"
                    placeholder={"Enter client amount "}
                    onChange={(e) => {
                      formik.handleChange(e)
                      if (e.target.value !== '') {
                        const amount = Number(e.target.value)
                        formik.setFieldValue("service_fee", amount);
                        // setClientAmount(amount)
                      }
                    }}
                    onBlur={formik.handleBlur}
                    // inputProps={{ inputProps: { min: 0 } }}
                    inputProps={{ inputProps: { min: 0, step: ".01" } }}
                    value={formik.values.service_fee}
                    error={formik.touched.service_fee &&
                      Boolean(formik.errors.service_fee)
                    }
                    helperText={
                      formik.touched.service_fee && formik.errors.service_fee
                    }

                  />
                </div>
                <div>
                  <p className='flight_ticket_form_label'>GST</p>
                  <CustomTextField
                    type={'number'}
                    isDisabled={booking_id && (isView && !isUpdate)}
                    name="gst"
                    placeholder={"Enter client amount "}
                    onChange={(e) => {
                      formik.handleChange(e)
                      if (e.target.value !== '') {
                        const amount = Number(e.target.value)
                        formik.setFieldValue("gst", amount);
                        // setClientAmount(amount)
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.gst}
                    error={formik.touched.gst &&
                      Boolean(formik.errors.gst)
                    }
                    inputProps={{ inputProps: { min: 0, step: ".01" } }}
                    helperText={
                      formik.touched.gst && formik.errors.gst
                    }
                  />
                </div>
                <div>
                  <p className='flight_ticket_form_label'>TCS</p>
                  <CustomTextField
                    type={'number'}
                    isDisabled={booking_id && (isView && !isUpdate)}
                    name="tcs"
                    placeholder={"Enter tcs "}
                    onChange={(e) => {
                      formik.handleChange(e)
                      if (e.target.value !== '') {
                        const amount = Number(e.target.value)
                        formik.setFieldValue("tcs", amount);
                        // setClientAmount(amount)
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.tcs}
                    error={formik.touched.tcs &&
                      Boolean(formik.errors.tcs)
                    }
                    inputProps={{ inputProps: { min: 0, step: ".01" } }}
                    helperText={
                      formik.touched.tcs && formik.errors.tcs
                    }
                  />
                </div>

              </div>

            </div>

            <div className='packages_form_main_container'>
              <p className="packages_form_legend"> Miscellaneous</p>
              <div className='packages_form_sub_container_0'>

                <div>
                  <p className='packages_form_label'>Narration</p>
                  <CustomTextArea
                    rows={5}
                    isDisabled={booking_id && (isView && !isUpdate)}
                    name="narration"
                    placeholder={"Enter a narration"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik?.values?.narration}
                  />
                </div>

                <div>
                  <p className='packages_form_label'>Comments</p>
                  <CustomTextArea
                    isDisabled={booking_id && (isView && !isUpdate)}
                    rows={5}
                    name="comments"
                    placeholder={"Enter Comment"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.comments}
                  />
                </div>

                <div>
                  <p className='packages_form_label'>Reference</p>
                  <CustomTextField
                    isDisabled={booking_id && (isView && !isUpdate)}

                    name="reference"
                    placeholder={"Enter Reference"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.reference}
                  />
                </div>
                {/* <PackagesAdditionalInfo isDisabled={booking_id && (isView && !isUpdate)} client_creation_modal={client_creation_modal} formik={formik} attachmnetList={fileAttachments} setAttachmentList={set_attachment_list} attachmentDelete={delete_particular_attachment_details} /> */}
              </div>

              <div style={{ display: 'flex', alignItems: 'center', gap: '30px', marginTop: 10 }}>
                <div>
                  <div className='m-4' style={{ display: 'flex', alignItems: 'center', gap: '10px' }}   >
                    {booking_id && (isView && !isUpdate) ? "" : (<>
                      <CustomTextField
                        isDisabled={booking_id && (isView && !isUpdate)}
                        name="pnr_nos"
                        placeholder="Enter PNR"
                        onChange={(e) => {
                          formik.handleChange(e);
                          const values = e.target.value;
                          formik.setFieldValue("pnr_nos", values);
                          setPnr(values);
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.pnr_nos}
                      />

                      <button
                        hidden={booking_id && (isView && !isUpdate)}
                        className="flight_ticket_form_save_button"
                        type="button"
                        onClick={handleAdd}
                      >
                        Add
                      </button>
                    </>)}


                  </div>
                  {chipData && chipData.length > 0 && (
                    <>
                      {booking_id && (isView && !isUpdate) ? (<>
                        <Paper
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: 1,
                            padding: '8px',
                            flexWrap: 'wrap',
                            width: 500,
                            // maxWidth: chipData.length > 3 ? '150px' : 'auto', 
                            overflowY: chipData.length > 3 ? 'auto' : 'visible',
                          }}
                        >

                          {chipData?.map((data) => (
                            <Chip
                              key={data.key}
                              label={data.label}
                            // onDelete={handleDelete(data)}
                            />
                          ))}
                        </Paper>
                      </>) : (<>
                        <Paper
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: 1,
                            padding: '8px',
                            flexWrap: 'wrap',
                            width: 500,
                            // maxWidth: chipData.length > 3 ? '150px' : 'auto', 
                            overflowY: chipData.length > 3 ? 'auto' : 'visible',
                          }}
                        >

                          {chipData?.map((data) => (
                            <Chip
                              key={data.key}
                              label={data.label}
                              onDelete={handleDelete(data)}
                            />
                          ))}
                        </Paper>
                      </>)}

                    </>
                    // <Paper
                    //   sx={{
                    //     display: 'flex',
                    //     flexDirection: 'row',
                    //     gap: 1,
                    //     padding: '8px',
                    //     flexWrap: 'wrap',
                    //     width: 500,
                    //     // maxWidth: chipData.length > 3 ? '150px' : 'auto', 
                    //     overflowY: chipData.length > 3 ? 'auto' : 'visible',
                    //   }}
                    // >

                    //   {chipData?.map((data) => (
                    //     <Chip
                    //       key={data.key}
                    //       label={data.label}
                    //     // onDelete={handleDelete(data)}

                    //     // onDelete={
                    //     //   isView!==null ? undefined : () => handleDelete(data)
                    //     // }
                    //     />
                    //   ))}
                    // </Paper>
                  )}
                </div>
                <PackagesAdditionalInfo isDisabled={booking_id && (isView && !isUpdate)} client_creation_modal={client_creation_modal} formik={formik} attachmnetList={fileAttachments} setAttachmentList={set_attachment_list} attachmentDelete={delete_particular_attachment_details} />

              </div>
            </div>

            <div className='flight_ticket_form_main_container'>
              <p className="flight_ticket_form_legend">Supplier Information</p>
              {!isView && (
                <div className='packages_form_label_with_action_container'>
                  <p className='packages_form_label_with_action'>Supplier</p>
                  <CgAddR className='packages_booking_label_action' onClick={() => {
                    handleSupplierCreationModal(true)
                  }} />
                </div>
              )}
              <PackagesSupplierTable isDisable={booking_id && (isView && !isUpdate)} passengerCount={passengerCount} passengerOtherCount={passengerOtherCount} passengerList={passengerList} setPassengerList={set_passenger_list} updatePassengerDetails={update_passenger_list} passengerDelete={delete_particular_passenger_details} />
            </div>
          </form>
          <div className='flight_ticket_form_footer_actions'>
            {/* {!booking_id && (isView && !isUpdate) &&} */}

            {/* {isSaveAsDraft && !isView && (
              <div className='flight_ticket_form_single_row_alignment' >
                {!booking_id ? <button className='flight_ticket_form_reset_button' tabIndex='14' onClick={() => {
                  formik.resetForm()
                  resetState()
                }}>Reset</button> : <></>}

                <button
                  className='flight_ticket_form_save_button'
                  type="submit"
                  form="packagesForm" onClick={() => {
                    // setIsSaveAsDraft(true)
                  }}>Save as Draft</button>

                <button
                  className='packages_form_save_button'
                  type="submit"
                  form="packagesForm" onClick={() => {
                    // setIsSaveAsDraft(false)
                  }}>Submit</button>

              </div>
            )} */}

            {isSaveAsDraft && !isView && (
              <div className="flight_ticket_form_single_row_alignment">
                {!booking_id ? (
                  <button
                    className="flight_ticket_form_reset_button"
                    tabIndex="14"
                    onClick={() => {
                      formik.resetForm();
                      resetState();
                    }}
                  >
                    Reset
                  </button>
                ) : null}
                {Is_Package_Draft && <button
                  className="flight_ticket_form_save_button"
                  type="submit"
                  form="packagesForm"
                  onClick={(e) => {
                    setIsDraft(true);
                    // handleFormSubmit(e);
                  }}
                >
                  Save as Draft
                </button>}

                {Is_Package_Submit && <button
                  className="packages_form_save_button"
                  type="submit"
                  form="packagesForm"
                  onClick={(e) => {
                    setIsDraft(false);
                    // handleFormSubmit(e);
                  }}
                >
                  Submit
                </button>}

              </div>
            )}
          </div>


        </div>
      </div>
      {/* client registration modal */}
      <CustomModal
        maxWidth={"1500px"}
        open={client_creation_modal}
        handleClose={handleClientCreationModal}
        close={<IconButton
          aria-label="close"
          onClick={() => {
            handleClientCreationModal(false)
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        > <IoIosCloseCircleOutline />
        </IconButton>}
        body={
          <ClientForm is_open_modal={true} close_modal={handleClientCreationModal} />
        }
        title={"Client Registartion"}
      />

      {/* {Hotel creation modal} */}
      <CustomModal
        maxWidth={"1500px"}
        open={hotel_creation_modal}
        handleClose={handleHotelCreationModal}
        close={<IconButton
          aria-label="close"
          onClick={() => {
            handleHotelCreationModal(false)
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        > <IoIosCloseCircleOutline />
        </IconButton>}
        body={
          <HotelForm is_open_modal={true} close_modal={handleHotelCreationModal} />
        }
        title={"Hotel Registartion"}
      />

      {/* {supplier registration modal} */}
      <CustomModal
        maxWidth={"1500px"}
        open={supplier_creation_modal}
        handleClose={handleSupplierCreationModal}
        close={<IconButton
          aria-label="close"
          onClick={() => {
            handleSupplierCreationModal(false)
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        > <IoIosCloseCircleOutline />
        </IconButton>}
        body={
          <SupplierForm is_open_modal={true} close_modal={handleSupplierCreationModal} />
        }
        title={"Supplier Registartion"}
      />

    </div>
  )
}

export default PackagesForm