import React, { useEffect, useState } from 'react'
import CustomModal from '../../../../../components/custom_modal/CustomModal'
import CustomTextArea from '../../../../../components/custom_text_area/CustomTextArea'
import CustomTextField from '../../../../../components/custom_text_field/CustomTextField'
// import './CancelPackages.css'
import { CommonHelpers } from '../../../../../services/commonHelpers'
import { RiDownloadLine } from "react-icons/ri";
import { MdDeleteOutline } from "react-icons/md";
import { approve_or_reject_filght_ticket, flight_ticket_cancellation_reasons } from '../../../../../api/process_ecr/flightTicketApis'
import { ErrorToast, SuccessToast } from '../../../../../toast/toasts'
import dayjs from 'dayjs'
import CustomAutoCompleteDropdown from '../../../../../components/custom_auto_complete_dropdown/CustomAutoCompleteDropdown'
import { package_cancel } from '../../../../../api/packageApis'
function CancelPackages({ selected_item, open_modal, modal_close }) {
    const [cancelTicketReasons, setCancelTicketReasons] = useState([])

    const [basicDetails, setBasicDetails] = useState({
        service_fee: '',
        supplier_amount: '',
        comment: '',
        cancel_reason: ''
    })

    const get_passenger_cancel_reasons = () => {
        flight_ticket_cancellation_reasons().then((response) => {
            const reStructureResponse = response.data.map((item) => { return { 'value': item.CRL_id, 'label': item.CRL_reason } })
            setCancelTicketReasons(reStructureResponse)
        }).catch((error) => {
            console.log(error)
        })
    }

    useEffect(() => {
        if (open_modal === true) {
            get_passenger_cancel_reasons()
        }
    }, [open_modal])


    const [errors, setErrors] = useState({});

    // Function to reset fields and errors
    const resetFields = () => {
        setBasicDetails({
            service_fee: 0,
            supplier_amount: 0,
            comment: '',
            cancel_reason: ''
        });
        setErrors({});
    };

    const validateFields = () => {
        const newErrors = {};

        // Validate Service Fee
        if (basicDetails.service_fee === 0 || isNaN(basicDetails.service_fee)) {
            newErrors.service_fee = "Service Fee is required and must be greater than 0";
        }

        // Validate Supplier Amount
        if (basicDetails.supplier_amount === 0 || isNaN(basicDetails.supplier_amount)) {
            newErrors.supplier_amount = "Supplier Amount is required and must be greater than 0";
        }

        // Validate Cancel Reason
        if (!basicDetails.cancel_reason) {
            newErrors.cancel_reason = "Cancel Reason is required";
        }

        // Validate Comment
        if (!basicDetails.comment) {
            newErrors.comment = "Comment is required";
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0; // Returns true if no errors
    };

    const onSubmit = () => {
        if (validateFields()) {
            const payload = {
                service_fee: basicDetails.service_fee,
                supplier_amount: basicDetails.supplier_amount,
                comment: basicDetails.comment,
                cancel_reason: basicDetails.cancel_reason,
                booking_id: selected_item?.id
            }
            package_cancel(payload).then((response) => {

                if (response.data.status === true) {
                    SuccessToast(response.data.message)
                    modal_close(false);
                    resetFields()

                } else {
                    ErrorToast(response?.data?.message)
                }
            }).catch((error) => {
                console.log(error)
                ErrorToast(`Something went wrong!`)

            })
        } else {
            console.log("Validation failed:", errors);
        }
    };

    return (
        <div>
            <CustomModal
                open={open_modal}
                handleClose={(e) => {
                    modal_close(false);
                    resetFields();
                }}
                body={
                    <div>
                        {/* Service Fee Input */}
                        <div>
                            <p className='finance_level_modal_label'>Service Fee <span className='label_required_remark'>*</span></p>
                            <CustomTextField
                                type="number"
                                placeholder="Enter service fee"
                                value={basicDetails.service_fee}
                                onChange={(e) => {
                                    const value = parseFloat(e.target.value) || 0;
                                    if (value >= 0) {
                                        setBasicDetails({ ...basicDetails, service_fee: value });
                                    }
                                }}
                            // onChange={(e) => setBasicDetails({ ...basicDetails, service_fee: parseFloat(e.target.value) || 0 })}
                            />
                            {errors.service_fee && <p className='comment_error_message'>{errors.service_fee}</p>}
                        </div>

                        {/* Supplier Amount Input */}
                        <div>
                            <p className='finance_level_modal_label'>Supplier Amount <span className='label_required_remark'>*</span></p>
                            <CustomTextField
                                type="number"
                                placeholder="Enter supplier amount"
                                value={basicDetails.supplier_amount}
                                onChange={(e) => {
                                    const value = parseFloat(e.target.value) || 0;
                                    setBasicDetails({ ...basicDetails, supplier_amount: value >= 0 ? value : 0 });
                                }}
                            // onChange={(e) => setBasicDetails({ ...basicDetails, supplier_amount: parseFloat(e.target.value) || 0 })}
                            />
                            {errors.supplier_amount && <p className='comment_error_message'>{errors.supplier_amount}</p>}
                        </div>

                        {/* Cancel Reason Dropdown */}
                        <div>
                            <p className='finance_level_modal_label'>Cancel Reason <span className='flight_cancel_form_label_required'>*</span></p>
                            <CustomAutoCompleteDropdown
                                placeholder="Select reason"
                                options={cancelTicketReasons}
                                onChange={(event, newValue) => {
                                    setBasicDetails({
                                        ...basicDetails,
                                        cancel_reason: newValue?.value,
                                        cancellation_reason_label: newValue?.label
                                    });
                                }}
                            />
                            {errors.cancel_reason && <p className='comment_error_message'>{errors.cancel_reason}</p>}
                        </div>

                        {/* Comment Input */}
                        <div>
                            <p className='finance_level_modal_label'>Comment <span className='label_required_remark'>*</span></p>
                            <CustomTextArea
                                rows={5}
                                placeholder="Enter a comment"
                                value={basicDetails.comment}
                                onChange={(e) => setBasicDetails({ ...basicDetails, comment: e.target.value })}
                            />
                            {errors.comment && <p className='comment_error_message'>{errors.comment}</p>}
                        </div>

                        {/* Action Buttons */}
                        <div className='finance_level_modal_form_single_row_alignment'>
                            <button
                                className='finance_level_modal_form_reset_button'
                                onClick={() => {
                                    modal_close(false);
                                    resetFields();
                                }}
                            >
                                Cancel
                            </button>
                            <button
                                className='finance_level_modal_form_update_button'
                                onClick={onSubmit}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                }
                title={"Cancel Package"}
            />
        </div>
    )
}

export default CancelPackages