import React, { useEffect, useState } from 'react'
import CustomTable from '../../../../../components/custom_table/CustomTable'
import './SalesReport.css'
import { Select, MenuItem, FormControl, Box, IconButton } from '@mui/material';
import CustomBottomPagination from '../../../../../components/custom_bottom_pagination/CustomBottomPagination';
import { ApiConfig } from '../../../../../api/apiConfig';
import { ErrorToast } from '../../../../../toast/toasts';
import { SiMicrosoftexcel } from "react-icons/si";
import * as XLSX from 'xlsx';
import { useSelector } from 'react-redux';
const SalesReports = () => {
    const isCollapsed = useSelector((state) => state.commonStore.isCollapsed)
    const currentMonth = new Date().getMonth() + 1; // Get month (1-12)
    const formattedMonth = String(currentMonth).padStart(2, '0'); // Add leading zero if needed
    const [selectedOption, setSelectedOption] = useState('B2B'); // Selected option state
    const [pageNumber, setPageNumber] = useState(1)
    const [totalPages, setTotalPages] = useState(0)
    const [totalItemPerPage, setTotalItemPerPage] = useState(25)
    const [reportList, setReportList] = useState([])
    const [monthselected, setMonthselected] = useState(formattedMonth)
    const [monthName, setmonthName] = useState("January")


    useEffect(() => {

        if (selectedOption === 'B2B') {
            getB2Breport();
        } else if (selectedOption === 'B2C') {
            getB2Creport();
        }
    }, [pageNumber, totalItemPerPage, monthselected])

    //----------------FOR HANDLE PAGINATION START
    const getB2Breport = async () => {
        const formData = new FormData();
        formData.append('page', pageNumber);
        formData.append('pageItem_count', totalItemPerPage);
        formData.append('month', monthselected);

        const response = await ApiConfig.post('/flightBookingReport_B2B', formData).then((response) => {
            if (response?.data?.data?.data) {
                setReportList(response?.data?.data?.data)
                setTotalPages(response?.data?.data?.last_page)
            } else {
                setReportList([])
                setTotalPages(0)
                ErrorToast(response?.data?.message)
            }
        }).catch((error) => {
            let message = "";
            for (const key in error?.data?.errors) {
                if (error?.data?.errors.hasOwnProperty(key)) {
                    const keyMessages = error?.data?.errors[key].map(message => message);
                    message = message.concat(keyMessages);
                }
            }
            ErrorToast(message)
        })
    }

    const getB2Creport = async () => {
        const formData = new FormData();
        formData.append('page', pageNumber);
        formData.append('pageItem_count', totalItemPerPage);
        formData.append('month', monthselected);

        const response = await ApiConfig.post('/flightBookingReport_B2C', formData).then((response) => {
            if (response?.data?.data?.data) {
                setReportList(response?.data?.data?.data)
                setTotalPages(response?.data?.data?.last_page)
            } else {
                setReportList([])
                setTotalPages(0)
                ErrorToast(response?.data?.message)
            }
        }).catch((error) => {
            let message = "";
            for (const key in error?.data?.errors) {
                if (error?.data?.errors.hasOwnProperty(key)) {
                    const keyMessages = error?.data?.errors[key].map(message => message);
                    message = message.concat(keyMessages);
                }
            }
            ErrorToast(message)
        })
    }
    //----------------FOR HANDLE PAGINATION END

    const getB2BreportWithoutPagination = async () => {
        const req = {
            month: monthselected
        }
        const response = await ApiConfig.post('/flightBookingReport_B2B', req).then((response) => {
            if (response?.data?.data?.data) {
                setReportList(response?.data?.data?.data)
                setTotalPages(response?.data?.last_page)
            } else {
                setReportList([])
                setTotalPages(0)
                ErrorToast(response?.data?.message)
            }
        }).catch((error) => {
            let message = "";
            for (const key in error?.data?.errors) {
                if (error?.data?.errors.hasOwnProperty(key)) {
                    const keyMessages = error?.data?.errors[key].map(message => message);
                    message = message.concat(keyMessages);
                }
            }
            ErrorToast(message)
        })
    }

    const getB2CreportWithoutPagination = async () => {
        const req = {
            month: monthselected
        }
        const response = await ApiConfig.post('/flightBookingReport_B2C', req).then((response) => {
            if (response?.data?.data?.data) {
                setReportList(response?.data?.data?.data)
                setTotalPages(response?.data?.last_page)
            } else {
                setReportList([])
                setTotalPages(0)
                ErrorToast(response?.data?.message)
            }

        }).catch((error) => {
            let message = "";
            for (const key in error?.data?.errors) {
                if (error?.data?.errors.hasOwnProperty(key)) {
                    const keyMessages = error?.data?.errors[key].map(message => message);
                    message = message.concat(keyMessages);
                }
            }
            ErrorToast(message)
        })
    }


    // Handle the change of the select box
    const handleChange = (event) => {
        setPageNumber(1)
        setSelectedOption(event.target.value);
        if (event.target.value === 'B2B') {
            getB2Breport();
        } else if (event.target.value === 'B2C') {
            getB2Creport();
        }
    };
    // useEffect(() => {
    //     if (selectedOption === 'B2B') {
    //         getB2Breport();
    //     } else if (selectedOption === 'B2C') {
    //         getB2Creport();
    //     }
    // }, [monthselected])
    const updatePagination = (pageNumber) => {
        setPageNumber(pageNumber)
    }
    const updateItemPageCount = (pageItemCount) => {
        setTotalItemPerPage(pageItemCount)
    }

    const Columns = [
        {
            title: "Sl.No",
            dataIndex: "key",
            key: "key",

        },
        {
            title: "Invoice Date",
            dataIndex: "Invoice_Date",
            key: "Invoice_Date",
        },
        {
            title: "Invocie No",
            dataIndex: "Invocie_No",
            key: "Invocie_No",
        },
        {
            title: "Name of Client",
            dataIndex: "Name_of_Client",
            key: "Name_of_Client",
        },
        {
            title: "GSTIN No",
            dataIndex: "GSTIN_No",
            key: "GSTIN_No",
        },
        {
            title: "Service Details",
            dataIndex: "Service_Details",
            key: "Service_Details",
        },
        {
            title: "PNR #",
            dataIndex: "PNR_NO",
            key: "PNR_NO",
        },
        {
            title: "Taxable  Amount",
            dataIndex: "Taxable_Amount",
            key: "Taxable_Amount",
        },
        {
            title: "GST Rate (18%)",
            dataIndex: "GST_Rate",
            key: "GST_Rate",
        },
        {
            title: "CGST (9%)",
            dataIndex: "CGST",
            key: "CGST",
        },
        {
            title: "SGST (9%)",
            dataIndex: "SGST",
            key: "SGST",
        },
        {
            title: "IGST (18%)",
            dataIndex: "IGST",
            key: "IGST",
        },
        {
            title: "Total GST",
            dataIndex: "Total_GST",
            key: "Total_GST",
        },
        {
            title: "Supplier Amount",
            dataIndex: "Supplier_Amount",
            key: "Supplier_Amount",
        },
        {
            title: "Agent Commision",
            dataIndex: "Agent_Commision",
            key: "Agent_Commision",
        },
        {
            title: "LAS Margin",
            dataIndex: "LastMargin",
            key: "LastMargin",
        },
        {
            title: "Total Invoice Value",
            dataIndex: "TotalInvoice_value",
            key: "TotalInvoice_value",
        },
        {
            title: "Exempted",
            dataIndex: "Exempted",
            key: "Exempted",
        },
        {
            title: "Refund",
            dataIndex: "Refund",
            key: "Refund",
        },
        {
            title: "Total Sales",
            dataIndex: "Total_Sales",
            key: "Total_Sales",
        },
        {
            title: "Remarks",
            dataIndex: "Remarks",
            key: "Remarks",
        }
    ]
    const Columns_B2C = [
        {
            title: "Sl.No",
            dataIndex: "key",
            key: "key",

        },
        {
            title: "Invoice Date",
            dataIndex: "Invoice_Date",
            key: "Invoice_Date",
        },
        {
            title: "Invocie No",
            dataIndex: "Invocie_No",
            key: "Invocie_No",
        },
        {
            title: "Name of Client",
            dataIndex: "Name_of_Client",
            key: "Name_of_Client",
        },
        {
            title: "GSTIN No",
            dataIndex: "GSTIN_No",
            key: "GSTIN_No",
        },
        {
            title: "Service Details",
            dataIndex: "Service_Details",
            key: "Service_Details",
        },
        {
            title: "PNR #",
            dataIndex: "PNR_NO",
            key: "PNR_NO",
        },
        {
            title: "Taxable  Amount",
            dataIndex: "Taxable_Amount",
            key: "Taxable_Amount",
        },
        {
            title: "GST Rate (18%)",
            dataIndex: "GST_Rate",
            key: "GST_Rate",
        },
        {
            title: "CGST (9%)",
            dataIndex: "CGST",
            key: "CGST",
        },
        {
            title: "SGST (9%)",
            dataIndex: "SGST",
            key: "SGST",
        },
        {
            title: "IGST (18%)",
            dataIndex: "IGST",
            key: "IGST",
        },
        {
            title: "Total GST",
            dataIndex: "Total_GST",
            key: "Total_GST",
        },
        {
            title: "Supplier Amount",
            dataIndex: "Supplier_Amount",
            key: "Supplier_Amount",
        },
        {
            title: "Agent Commision",
            dataIndex: "Agent_Commision",
            key: "Agent_Commision",
        },
        {
            title: "LAS Margin",
            dataIndex: "LastMargin",
            key: "LastMargin",
        },
        {
            title: "Total Invoice Value",
            dataIndex: "TotalInvoice_value",
            key: "TotalInvoice_value",
        },
        {
            title: "Exempted",
            dataIndex: "Exempted",
            key: "Exempted",
        },
        {
            title: "Refund",
            dataIndex: "Refund",
            key: "Refund",
        },
        {
            title: "Total Sales",
            dataIndex: "Total_Sales",
            key: "Total_Sales",
        },
        {
            title: "Remarks",
            dataIndex: "Remarks",
            key: "Remarks",
        },

    ]
    const datas = () => {
        const result =
            reportList && reportList.map((item, index) => {
                return {
                    key: pageNumber * totalItemPerPage - (totalItemPerPage - (index + 1)),
                    Invocie_No: item.FBD_invoice_number,
                    Supplier_Amount: item.SupplierAmount,
                    Taxable_Amount: item.TaxableAmount,
                    Name_of_Client: item.client_name,
                    GSTIN_No: item.gst_number,
                    LastMargin: item.las_Margin,
                    CGST: item.CGST,
                    Exempted: item.Exepmted,
                    IGST: item.IGST,
                    Refund: item.Refund,
                    Remarks: item.Remark,
                    SGST: item.SGST,
                    Total_GST: item.TotalGST,
                    TotalInvoice_value: item.TotalInvoice,
                    Total_Sales: item.Totalsale,
                    Agent_Commision: item.agent_commission,
                    Total_Sales: item.Totalsale,
                    Total_Sales: item.Totalsale,
                    Invoice_Date: item.Invoice_date,
                    Service_Details: item.Service_Details,
                    PNR_NO: item.PNR_NO


                };
            });
        return result;
    };
    const months = [
        { label: 'January', value: '01' },
        { label: 'February', value: '02' },
        { label: 'March', value: '03' },
        { label: 'April', value: '04' },
        { label: 'May', value: '05' },
        { label: 'June', value: '06' },
        { label: 'July', value: '07' },
        { label: 'August', value: '08' },
        { label: 'September', value: '09' },
        { label: 'October', value: '10' },
        { label: 'November', value: '11' },
        { label: 'December', value: '12' }
    ];
    const getmonthName = (value) => {
        const res = months && months.filter((val) => {
            return Number(val.value) === Number(value)
        })
        setmonthName(res?.[0].label)
    }

    // Function to fetch report data and export it as an Excel file BY SREERAJ 13-11-24
    const handleExport = async () => {
        try {
            const req = {
                month: monthselected,
            };
            const url = selectedOption === `B2B` ? `/flightBookingReport_B2B` : `/flightBookingReport_B2C`
            const response = await ApiConfig.post(url, req);

            if (response?.data?.data?.data) {
                const reportList = response.data.data.data;

                // data for Excel export
                const result = reportList?.map((item) => ({
                    "Invoice Date": item.Invoice_date,
                    "Invoice No": item.FBD_invoice_number,
                    "Name of Client": item.client_name,
                    "GSTIN No": item.gst_number,
                    "Service Details": item.Service_Details,
                    "PNR #": item.PNR_NO,
                    "Taxable Amount": item.TaxableAmount,
                    "GST Rate (18%)": item.Gst_rate,
                    "CGST (9%)": item.CGST,
                    "SGST (9%)": item.SGST,
                    "IGST (18%)": item.IGST,
                    "Total GST": item.TotalGST,
                    "Supplier Amount": item.SupplierAmount,
                    "Agent Commission": item.agent_commission,
                    "Last Margin": item.las_Margin,
                    "Total Invoice Value": item.TotalInvoice,
                    "Exempted": item.Exepmted,
                    "Refund": item.Refund,
                    "Total Sales": item.Totalsale,
                    "Remarks": item.Remark,
                }));

                // download the Excel file
                if (result.length > 0) {
                    const workbook = XLSX.utils.book_new();
                    const worksheet = XLSX.utils.json_to_sheet(result);
                    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

                    const fileName = `${selectedOption}_${months.find(item => item.value === monthselected)?.label}_${new Date().toLocaleDateString()}_report.xlsx`;
                    XLSX.writeFile(workbook, fileName);
                } else {
                    ErrorToast("No record found");
                }
            } else {
                ErrorToast(response?.data?.message || "No data available");
            }
        } catch (error) {
            let message = "An error occurred";
            ErrorToast(message);
        }
    };

    //OLD FUNCTION FOR EXPORT INTO EXCEL
    // const export_report = () => {

    //     try {
    //         const result =
    //             reportList && reportList.map((item, index) => {
    //                 return {
    //                     "Invoice Date": item.Invoice_date,
    //                     "Invocie No": item.FBD_invoice_number,
    //                     "Name of Client": item.client_name,
    //                     "GSTIN No": item.gst_number,
    //                     "Service Details": item.Service_Details,
    //                     "PNR #": item.PNR_NO,
    //                     "Taxable  Amount": item.TaxableAmount,
    //                     "GST Rate": item.Gst_rate,
    //                     "CGST": item.CGST,
    //                     "SGST": item.SGST,
    //                     "IGST": item.IGST,
    //                     "Total GST": item.TotalGST,
    //                     "Supplier Amount": item.SupplierAmount,
    //                     "Agent Commision": item.agent_commission,
    //                     "Last Margin": item.las_Margin,
    //                     "Total Invoice Value": item.TotalInvoice,
    //                     "Exempted": item.Exepmted,
    //                     "Refund": item.Refund,
    //                     "Total Sales": item.Totalsale,
    //                     "Remarks": item.Remark,
    //                 };
    //             });
    //         if (result?.length > 0) {
    //             // Create a new workbook
    //             const workbook = XLSX.utils.book_new();

    //             // Convert JSON data to a worksheet
    //             const worksheet = XLSX.utils.json_to_sheet(result);

    //             // Append the worksheet to the workbook
    //             XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    //             // Export the workbook as an Excel file
    //             XLSX.writeFile(workbook, `${selectedOption}_${months.find((item) => item.value == monthselected).label}_${new Date().toLocaleDateString()}_report.xlsx`);
    //         } else {
    //             ErrorToast("No record found")
    //         }

    //     } catch (error) {
    //         console.log(error)
    //     }

    // }

    // Get privileges details from local storage
    const getPrivilegesDetails = () => JSON.parse(localStorage.getItem("privileges_details") || "[]");

    // Check if a specific privilege exists in User Management -> User
    const hasPrivilege = (privilegeName) =>
        getPrivilegesDetails().some(
            (item) =>
                item.module_name === "Reports" &&
                item.submodules?.["Sales"]?.privileges?.some((priv) => priv.name === privilegeName)
        );
    const isSales_Download = hasPrivilege("Download");

    return (
        <div className='sales_report_main_container' style={{ width: isCollapsed ? '90vw' : '82vw' }}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    gap: 2, // Adjust gap for proper spacing between select boxes
                }}
            >
                <FormControl>
                    <Select
                        sx={{
                            width: 200,
                            height: 30,
                            borderColor: '#074D4D',
                            background: '#1c7777',
                            color: 'white',
                            '&:hover': {
                                borderColor: '#074D4D',
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#074D4D', // Apply border color when focused
                            },
                        }}
                        value={selectedOption}
                        onChange={(e) => handleChange(e)}
                    >
                        <MenuItem value="B2B">B2B Sales</MenuItem>
                        <MenuItem value="B2C">B2C Sales</MenuItem>
                    </Select>
                </FormControl>

                <FormControl>
                    <Select
                        sx={{
                            width: 200,
                            height: 30,
                            borderColor: '#074D4D',
                            background: '#1c7777',
                            color: 'white',
                            '&:hover': {
                                borderColor: '#074D4D',
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#074D4D', // Apply border color when focused
                            },
                        }}
                        value={monthselected}
                        onChange={(e) => {
                            setPageNumber(1)
                            setMonthselected(e.target.value);
                            getmonthName(e.target.value);
                        }}
                    >
                        {months &&
                            months.map((val) => {
                                return <MenuItem value={val.value}>{val.label}</MenuItem>;
                            })}
                    </Select>
                </FormControl>
                {isSales_Download && <IconButton onClick={() => {
                    handleExport()
                }}>
                    <SiMicrosoftexcel className='sales_excel_icon' />
                </IconButton>}


            </Box>

            {
                selectedOption === 'B2B' ? <div>
                    <div className='sales_report_header'>
                        <p className='sales_report_title'>{`Sales Report for the month of - ${monthName}`}  </p>
                    </div>
                    <div className='sales_report_header'>
                        <p className='sales_report_title'>B2 B Sales   </p>
                    </div>
                    <CustomTable columns={Columns} rows={datas()} />
                    <CustomBottomPagination pageFrom={`sales_report`} total_items_per_page={totalItemPerPage} total_page_count={totalPages} current_page={pageNumber} on_change_value={updatePagination} on_page_count_update={updateItemPageCount} />
                </div> : selectedOption === 'B2C' ? <div>
                    <div className='sales_report_header'>
                        <p className='sales_report_title'>{`Sales Report for the month of - ${monthName}`}  </p>
                    </div>
                    <div className='sales_report_header'>
                        <p className='sales_report_title'>B2 C Sales   </p>
                    </div>
                    <CustomTable columns={Columns_B2C} rows={datas()} />
                    <CustomBottomPagination pageFrom={`sales_report`} total_items_per_page={totalItemPerPage} total_page_count={totalPages} current_page={pageNumber} on_change_value={updatePagination} on_page_count_update={updateItemPageCount} />
                </div> : null

            }

        </div>
    )
}

export default SalesReports
