import React, { useEffect, useState } from 'react'
import './flightDateChangeFormStyle.css'
import { ErrorToast, SuccessToast } from '../../../../../toast/toasts'
import { flight_ticket_date_change, flight_ticket_date_change_reasons } from '../../../../../api/process_ecr/flightTicketApis'
import CustomDatePicker from '../../../../../components/custom_date_picker/CustomDatePicker'
import CustomAutoCompleteDropdown from '../../../../../components/custom_auto_complete_dropdown/CustomAutoCompleteDropdown'
import CustomTextArea from '../../../../../components/custom_text_area/CustomTextArea'
import CustomTextField from '../../../../../components/custom_text_field/CustomTextField'
import dayjs from "dayjs";
import CustomDeleteConfirmModal from '../../../../../components/custom_delete_confirm_modal/CustomDeleteConfirmModal'

function FlightDateChangeForm({ details, onClose }) {

    const [checkItems, setCheckedItems] = useState([])
    const [dateChangeModal, setDateChangeModal] = useState(false)
    const [selectedItem, setSelectedItem] = useState(null)
    const [formDetails, setFormDetails] = useState(null)
    const [cancelConfirmModal, setCancelConfirmModal] = useState(false)
    const [passengerCounts, setPassengerCounts] = useState({
        'adult': 0,
        'child': 0,
        'infants': 0
    })
    const [passengerDetails, setPassengerDetails] = useState([])
    const [dateChangePassengers, setDateChangePassengers] = useState([])
    const [basicDetails, setBasicDetails] = useState({
        date_change_reason_id: null,
        date_change_reason: '',
        remark: '',
        updated_on: ''
    })
    const [dateChangeFee, setDateChangeFee] = useState(0)
    const [dateChangeReasons, setDateChangeReasons] = useState([])
    const [dateChangeFieldValidation, setDateChangeFieldValidaion] = useState([])
    const [dateChangeFinalData, setDateChangeFinalData] = useState([])
    const [dateChangeConfirmation, setDateChangeConfirmation] = useState(false);
    useEffect(() => {
        get_passenger_date_change_reasons()
        passengerCount()
        setPassengerDetails(datas(details?.passanges))
    }, [details])

    useEffect(() => {
        const date_change_charge = passengerDetails?.filter((item) => item.is_checked)?.reduce((prev, curr) => { return prev + Number(curr.new_base_charge) + Number(curr.new_penality_charge) + Number(curr.new_service_charge) }, 0)
        setDateChangeFee(date_change_charge?.toFixed(2))
    }, [passengerDetails])

    const get_passenger_date_change_reasons = () => {
        flight_ticket_date_change_reasons().then((response) => {
            const reStructureResponse = response.data.map((item) => { return { 'value': item.DRL_id, 'label': item.DRL_reason } })
            setDateChangeReasons(reStructureResponse)
        }).catch((error) => {
            console.log(error)
        })
    }

    const passengerCount = () => {
        let adultCount = 0;
        let childCount = 0;
        let infantCount = 0;
        let passengerNet = 0
        details?.passanges?.filter((item) => item.FBPD_cancel_status !== 1).forEach((item) => {
            passengerNet = passengerNet + Number(item.FBPD_customer_net)
            if (item.FBPD_Passanger_type === 1) adultCount++;
            else if (item.FBPD_Passanger_type === 2) childCount++;
            else if (item.FBPD_Passanger_type === 3) infantCount++;

        });

        setPassengerCounts({
            adult: adultCount,
            child: childCount,
            infants: infantCount,
        });
    }


    const datas = (passengerDetails) => {
        const result =
            passengerDetails &&
            passengerDetails.filter((item) => item.FBPD_cancel_status !== 1).map((item, index) => {
                let Total_Customer_net = (item?.date_changes?.length > 0 && item?.date_changes[0]['Total_service_fee'] ? item?.date_changes[0]['Total_service_fee'] : 0) + (item?.date_changes?.length > 0 && item?.date_changes[0]['Total_supplier_amount'] ? item?.date_changes[0]['Total_supplier_amount'] : 0)
                console.log(Total_Customer_net)
                return {
                    index: index,
                    key: index + 1,
                    id: item.FBPD_id,
                    title_name: item.passengerTitleName,
                    name: item.FBPD_FullName,
                    passenger_type: item.FBPD_Passanger_type,
                    passenger_type_name: item.passengerTypeName,
                    ticket_number: item?.date_changes?.length > 0 && item?.date_changes[0]['FDCD_ticketnumber'] ? item?.date_changes[0]['FDCD_ticketnumber'] : item.FBPD_ticket_number,
                    supplier_amount: item?.date_changes?.length > 0 && item?.date_changes[0]['Total_supplier_amount'] ? item?.date_changes[0]['Total_supplier_amount'] : item.FBPD_supplier_amount,
                    service_charge: item?.date_changes?.length > 0 && item?.date_changes[0]['Total_service_fee'] ? item?.date_changes[0]['Total_service_fee'] : item.FBPD_service_fee,
                    customer_net: Total_Customer_net ? Total_Customer_net : item.FBPD_customer_net,
                    pnr_number: item?.date_changes?.length > 0 && item?.date_changes[0]['FDCD_changed_PNR'] ? item?.date_changes[0]['FDCD_changed_PNR'] : details.FBD_PNR,
                    return_date: item?.date_changes?.length > 0 && item?.date_changes[0]['FDCD_changed_return_date'] ? item?.date_changes[0]['FDCD_changed_return_date'] : details.FBD_return_date?.split('T')[0],
                    travel_date: item?.date_changes?.length > 0 && item?.date_changes[0]['FDCD_changed_travel_date'] ? item?.date_changes[0]['FDCD_changed_travel_date'] : details.FBD_travel_date?.split('T')[0],
                    booking_id: item.FBPD_booking_id,
                    passenger_id: item.FBPD_id,
                    trip_type: details?.FBD_trip_type,
                    new_pnr_number: null,
                    new_ticket_number: null,
                    new_travel_date: null,
                    new_return_date: null,
                    new_base_charge: 0,
                    new_penality_charge: 0,
                    new_service_charge: 0,
                    is_checked: false
                };
            });
        return result;
    };

    const onChange_checked_datechange = (row_index) => {
        if (checkItems?.includes(row_index)) {
            setCheckedItems(checkItems.filter((item) => item !== row_index))
            const get_Passenger_details = passengerDetails.map((item, index) => {
                if (row_index === index) {
                    return { ...item, ["is_checked"]: false }
                } else {
                    return { ...item }
                }
            })
            setPassengerDetails(get_Passenger_details)
        } else {
            const get_Passenger_details = passengerDetails.map((item, index) => {
                if (row_index === index) {
                    return { ...item, ["is_checked"]: true }
                } else {
                    return { ...item }
                }
            })
            setPassengerDetails(get_Passenger_details)
            setCheckedItems([...checkItems, row_index])
        }
    }
    const onChange_passenger_new_values = (row_index, name, value) => {
        const get_Passenger_details = passengerDetails.map((item, index) => {
            if (row_index === index) {
                return { ...item, [name]: value }
            } else {
                return { ...item }
            }

        })
        const removeErrormessage = dateChangeFieldValidation.filter((item) => item.index != row_index)
        setDateChangeFieldValidaion(removeErrormessage)
        setPassengerDetails(get_Passenger_details)
    }
    const handleDateChangeConfirmModal = (is_close) => {
        setDateChangeConfirmation(is_close);
        setDateChangeFinalData([])
    };
    const ConfirmDateChange = () => {
        setDateChangeConfirmation(false)
        flight_ticket_date_change(dateChangeFinalData).then((response) => {
            SuccessToast("Sucesssfully date changed")
            onClose(false)
        }).catch((error) => {
            console.log(error)
        })

    }
    const onProceed = () => {
        let FieldValidation = []
        const filteredOutCheckedPassengerDetails = passengerDetails.filter((item) => checkItems.includes(item.index))
        filteredOutCheckedPassengerDetails.map((item) => {
            const error = {
                index: item.index
            }
            if (!item.new_pnr_number?.trim()) {
                error['new_pnr_number'] = "PNR number is required"
            }
            if (!item.new_ticket_number?.trim()) {
                error['new_ticket_number'] = "Ticket number is required"
            }
            if (!item.new_travel_date?.trim()) {
                error['new_travel_date'] = "Travel date is required"
            }
            if (error.new_pnr_number || error.new_ticket_number || error.new_travel_date) {
                FieldValidation.push(error)
            }
        })
        if (FieldValidation && FieldValidation.length > 0) {
            setDateChangeFieldValidaion(FieldValidation)
        } else {
            if (checkItems.length === 0) return ErrorToast('Please select passenger')
            if (!basicDetails.remark || !basicDetails.updated_on || !basicDetails.date_change_reason_id) return ErrorToast(!basicDetails.remark ? "Remark is required." :!basicDetails.updated_on? "Modified_on is required.":"Date change reason is required")
            if (filteredOutCheckedPassengerDetails.length > 0) {
                const currentDate = dayjs();
                const reStructureResponseData = []
                filteredOutCheckedPassengerDetails.map((item) => {
                    const reStructuredItem = {
                        "bookingId": item.booking_id,
                        "FDCD_passangerID": item.passenger_id,
                        "OLD_PNR_Number": item.pnr_number,
                        "FDCD_changed_PNR": item.new_pnr_number,
                        "FDCD_ticketnumber": item.new_ticket_number,
                        "FDCD_changed_travel_date": item.new_travel_date,
                        "FDCD_changed_return_date": item.new_return_date,
                        "FDCD_base_fee": item.new_base_charge,
                        "FDCD_change_penalty": item.new_penality_charge,
                        "FDCD_service_fee": item.new_service_charge,
                        "FDCD_supplier_fee": 0,
                        "FDCD_gst": 0,
                        'FDCD_changed_issue_date': currentDate.format('DD-MM-YYYY'),
                        'FDCD_changed_date': basicDetails?.updated_on,
                        "FDCD_comment": basicDetails?.remark,
                        "Datechange_reason_id": basicDetails?.date_change_reason_id,
                        "FDCD_supplier_id": null,
                        "FBD_ECRstatusID": 14,//Date chnage initiated status
                        "FBRH_review_date": currentDate.format('DD-MM-YYYY HH:mm:ss')
                    };
                    reStructureResponseData.push(reStructuredItem)
                })
                setDateChangeFinalData(reStructureResponseData)
                setDateChangeConfirmation(true)
            } else {
                ErrorToast('Please select a passenger to date change')
            }
        }

    }
    return (
        <div>
            <div className='flight_ticket_date_change_main_container'>
                <p className="flight_ticket_date_change_legend">Reshedule Details</p>
                <div >
                    <div className='flight_date_change_passenger_count'>
                        <p>Adult : {passengerCounts.adult}</p>
                        <p>Child : {passengerCounts.child}</p>
                        <p>Infants : {passengerCounts.infants}</p>
                    </div>
                    {passengerDetails && passengerDetails.map((item, index) => {
                        const matchingItemValidation = dateChangeFieldValidation?.find((val) => val.index === item.index);
                        // console.log(item)
                        return (
                            <div className='flight_ticket_date_change_passenger_main_container'>

                                <div >
                                    <div className='flight_ticket_date_change_passenger_basic_details_with_checkbox_container'>
                                        <div>
                                            <input type='checkbox' className='custom_check_box_color' checked={item.is_checked} onChange={() => {
                                                onChange_checked_datechange(index)
                                            }} />
                                        </div>
                                        <div >
                                            <div className='flight_ticket_date_change_passenger_basic_details'>
                                                <p>Title : {item?.title_name}</p>
                                                <p>Name : {item?.name}</p>
                                                <p>Passenger Type : {item?.passenger_type_name}</p>
                                                <p>PAN Number : {item?.pnr_number}</p>
                                                <p>Ticket Number : {item?.ticket_number}</p>
                                                <p>Travel Date : {item?.travel_date}</p>
                                                {item?.return_date && (
                                                    <p>Return Date : {item?.return_date}</p>
                                                )}
                                            </div>
                                            <div className='flight_ticket_date_change_passenger_basic_details'>
                                                <p>Supplier Amount : {item?.supplier_amount}</p>
                                                <p>Service Charge : {item?.service_charge}</p>
                                                <p>Customer Net : {(Number(item?.service_charge) + Number(item?.supplier_amount))?.toFixed(2)}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <div>
                                                <div className='flight_ticket_date_change_new_form_sub_container_0'>

                                                    <div>
                                                        <p className='flight_ticket_date_change_form_label'>New PNR<span className='label_required_remark'>*</span></p>
                                                        <CustomTextField
                                                            isDisabled={!item.is_checked}
                                                            name="new_pnr_number"
                                                            placeholder={"Enter pnr"}
                                                            value={item.new_pnr_number}
                                                            onChange={(e) => {
                                                                const { name, value } = e.target
                                                                onChange_passenger_new_values(index, name, value)
                                                            }}

                                                        />
                                                        {matchingItemValidation?.new_pnr_number ? <p className='date_change_field_validation'>{matchingItemValidation?.new_pnr_number}</p> : <></>}
                                                    </div>

                                                    <div>
                                                        <p className='flight_ticket_date_change_form_label'>New Ticket Number<span className='label_required_remark'>*</span></p>
                                                        <CustomTextField
                                                            isDisabled={!item.is_checked}
                                                            name="new_ticket_number"
                                                            placeholder={"Enter ticket number"}
                                                            value={item.new_ticket_number}
                                                            onChange={(e) => {
                                                                const { name, value } = e.target
                                                                onChange_passenger_new_values(index, name, value)
                                                            }}
                                                        />
                                                        {matchingItemValidation?.new_ticket_number ? <p className='date_change_field_validation'>{matchingItemValidation?.new_ticket_number}</p> : <></>}
                                                    </div>

                                                    <div>
                                                        <p className='flight_ticket_date_change_form_label'>New Travel Date<span className='label_required_remark'>*</span></p>
                                                        <div style={{ width: "100%" }}>
                                                            <CustomDatePicker
                                                                disabled={!item.is_checked}
                                                                // minDate={dayjs(item?.travel_date, "DD-MM-YYYY")}
                                                                name="new_travel_date"
                                                                value={dayjs(item?.new_travel_date, "DD-MM-YYYY")}
                                                                onChange={(date) => {
                                                                    onChange_passenger_new_values(index, 'new_travel_date', date)
                                                                }}
                                                            />
                                                        </div>
                                                        {matchingItemValidation?.new_travel_date ? <p className='date_change_field_validation'>{matchingItemValidation?.new_travel_date}</p> : <></>}

                                                    </div>
                                                    <div>
                                                        <p className='flight_ticket_date_change_form_label'>New Return Date</p>
                                                        <div style={{ width: "100%" }}>
                                                            <CustomDatePicker
                                                                disabled={item.is_checked ? item?.passenger_type === 1 ? true : false : true}
                                                                minDate={dayjs(item?.travel_date, "DD-MM-YYYY")}
                                                                name="new_return_date"
                                                                value={dayjs(item?.new_return_date, "DD-MM-YYYY")}
                                                                onChange={(date) => {
                                                                    onChange_passenger_new_values(index, 'new_return_date', date)
                                                                }}
                                                            />
                                                        </div>
                                                        {matchingItemValidation?.new_return_date ? <p className='date_change_field_validation'>{matchingItemValidation?.new_return_date}</p> : <></>}
                                                    </div>
                                                    <div>
                                                        <p className='flight_ticket_date_change_form_label'>Base Fee</p>
                                                        <CustomTextField
                                                            isDisabled={!item.is_checked}
                                                            inputProps={{ inputProps: { min: 0 ,step:".01"} }}
                                                            type={'number'}
                                                            name="new_base_charge"
                                                            placeholder={"Enter base fee"}
                                                            value={item?.new_base_charge}
                                                            onChange={(e) => {
                                                                const { name, value } = e.target
                                                                onChange_passenger_new_values(index, name, value)
                                                            }}
                                                        />
                                                        {matchingItemValidation?.new_base_charge ? <p className='date_change_field_validation'>{matchingItemValidation?.new_base_charge}</p> : <></>}
                                                    </div>
                                                    <div>
                                                        <p className='flight_ticket_date_change_form_label'>Penality Fee</p>
                                                        <CustomTextField
                                                            isDisabled={!item.is_checked}
                                                            inputProps={{ inputProps: { min: 0 ,step:".01"} }}
                                                            type={'number'}
                                                            name="new_penality_charge"
                                                            placeholder={"Enter penality fee"}
                                                            value={item?.new_penality_charge}
                                                            onChange={(e) => {
                                                                const { name, value } = e.target
                                                                onChange_passenger_new_values(index, name, value)
                                                            }}
                                                        />
                                                        {matchingItemValidation?.new_penality_charge ? <p className='date_change_field_validation'>{matchingItemValidation?.new_penality_charge}</p> : <></>}
                                                    </div>
                                                    <div>
                                                        <p className='flight_ticket_date_change_form_label'>Service Fee</p>
                                                        <CustomTextField
                                                            isDisabled={!item.is_checked}
                                                            inputProps={{ inputProps: { min: 0 ,step:".01"} }}
                                                            type={'number'}
                                                            name="new_service_charge"
                                                            placeholder={"Enter Supplier fee"}
                                                            value={item?.new_service_charge}
                                                            onChange={(e) => {
                                                                const { name, value } = e.target
                                                                onChange_passenger_new_values(index, name, value)
                                                            }}
                                                        />
                                                        {matchingItemValidation?.new_service_charge ? <p className='date_change_field_validation'>{matchingItemValidation?.new_service_charge}</p> : <></>}
                                                    </div>
                                                    <div>
                                                        <p className='flight_ticket_date_change_form_label'>Passeget Total Fee</p>
                                                        <CustomTextField
                                                            isDisabled={true}
                                                            name="passenger_total_charge"
                                                            placeholder={"Enter passenger total Charge"}
                                                            value={(Number(item?.new_base_charge) + Number(item?.new_penality_charge) + Number(item?.new_service_charge)).toFixed(2)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}

                    {/* <CustomTableColumnWithEditableRow columns={Columns} rows={passengerDetails} checkItems={checkItems} no_border={true} /> */}
                </div>
            </div>
            <div className='flight_ticket_date_main_container'>
                <p className="flight_ticket_date_form_legend">Summary</p>
                <div className='flight_ticket_date_change_form_sub_container'>
                    <div >
                        <div className='flight_ticket_date_change_row_alignment'>
                            <p className='flight_ticket_date_change_label_total_text'>No Of Passenger selected</p>
                            <p className='flight_ticket_date_change_label_total_text'>{checkItems.length}</p>
                        </div>
                        <div className='flight_ticket_date_change_row_alignment'>
                            <p className='flight_ticket_date_change_label_total_text'>Total Payable Amount</p>
                            <p className='flight_ticket_date_change_label_total_text'>{dateChangeFee}</p>
                        </div>
                        <div className='flight_ticket_date_change_row_alignment' style={{ maxWidth: '500px' }}>
                            <p className='flight_ticket_date_change_label_text'>Modified On <span className='flight_date_change_form_label_required'>*</span></p>
                            <CustomDatePicker
                                name="updated_on"
                                onChange={(e) => {
                                    setBasicDetails({ ...basicDetails, ['updated_on']: e })
                                }}
                            />
                        </div>
                        <div className='flight_ticket_date_change_row_alignment' style={{ maxWidth: '500px' }}>
                            <p>Date change reason <span className='flight_date_change_form_label_required'>*</span></p>
                            <CustomAutoCompleteDropdown
                                name=""
                                placeholder={"Select reason"}
                                options={dateChangeReasons}
                                onChange={(event, newValue) => {
                                    setBasicDetails({ ...basicDetails, ['date_change_reason_id']: newValue?.value, ['date_change_reason']: newValue?.label })
                                }}
                            />
                        </div>
                    </div>
                    <div>
                        
                        <div className='flight_date_change_remark_container'>
                            <p className="flight_ticket_date_remark_legend">Remarks <span className='flight_date_change_form_label_required'>*</span></p>
                            <CustomTextArea
                                noBorder={true}
                                rows={6}
                                name="narration"
                                placeholder={"Enter a remarks"}
                                onChange={(e) => {
                                    setBasicDetails({ ...basicDetails, ['remark']: e.target.value })
                                }}

                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='flight_ticket_date_single_row_alignment'>
                <button className='flight_ticket_date_cancel_form_reset_button' onClick={() => {
                    onClose(false)
                }} >Close</button>
                <button
                    className='flight_ticket_date_cancel_form_save_button' onClick={() => {
                        onProceed()
                    }}>Proceed</button>
            </div>
            <CustomDeleteConfirmModal custom_text={' Are you sure you want to date change ?'} open_modal={dateChangeConfirmation} close_modal={handleDateChangeConfirmModal} confirm_function={ConfirmDateChange} />
        </div>
    )
}

export default FlightDateChangeForm