import React, { useEffect, useState } from 'react'
import './AuditLogsStyle.css'
import CustomBottomPagination from '../../../components/custom_bottom_pagination/CustomBottomPagination'
import CustomTable from '../../../components/custom_table/CustomTable'
import { ErrorToast } from '../../../toast/toasts'
import { get_auditlog_lists_with_pagination } from '../../../api/auditlogApis'
import AuditLogFilter from './audit_log_filter/AuditLogFilter'

function AuditLogs() {

    const [auditLogs, setAuditLogs] = useState([])
    const [pageNumber, setPageNumber] = useState(1)
    const [totalPages, setTotalPages] = useState(0)
    const [totalItemPerPage, setTotalItemPerPage] = useState(10)
    const [searchFilter, setSearchFilter] = useState(null)


    useEffect(() => {
        getAuditLogs(pageNumber, searchFilter)
    }, [pageNumber, totalItemPerPage])


    const getAuditLogs = (pageNumber, filterValue) => {
        const formData = new FormData();
        formData.append('page', pageNumber);
        formData.append('pageItem_count', totalItemPerPage);
        if (filterValue) {
            for (const key in filterValue) {
                formData.append(key, filterValue[key]);
            }
        }
        get_auditlog_lists_with_pagination(formData).then((response) => {
            if (response?.data.code === 200) {
                setAuditLogs(response?.data.data?.data)
                setTotalPages(response?.data?.data.last_page)
            } else {
                setAuditLogs([])
                setTotalPages(0)
            }
        }).catch((error) => {
            let message = error?.message || "Something went wrong!";
            // for (const key in error.data.errors) {
            //     if (error.data.errors.hasOwnProperty(key)) {
            //         const keyMessages = error.data.errors[key].map(message => message);
            //         message = message.concat(keyMessages);
            //     }
            // }
            ErrorToast(message)
        })
    }

    const Columns = [
        {
            title: "#",
            dataIndex: "key",
            key: "key",

        },
        {
            title: "Event ",
            dataIndex: "event",
            key: "event",
        },
        {
            title: "Activity",
            dataIndex: "activity",
            key: "activity",
        },
        {
            title: "Affected Area",
            dataIndex: "affected_area",
            key: "affected_area",
        },
        {
            title: "IP",
            dataIndex: "ip_address",
            key: "ip_address",
        },
        {
            title: "Date & Time",
            dataIndex: "date_time",
            key: "date_time",
        },
        // {
        //     title: "Status",
        //     dataIndex: "status",
        //     key: "status",
        //     wrapper: (row) => (
        //         <div >
        //             <p style={{ color: row.status == 'Active' ? '#0A9E02' : "#FF0000" }}>{row.status}</p>
        //         </div>
        //     ),
        // },
        // (Is_Clients_View || Is_Clients_Edit || Is_Clients_Delete) && {
        //     title: "Actions",
        //     dataIndex: "actions",
        //     key: "actions",
        //     action: (row) => (
        //         <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 10 }}>
        //             {Is_Clients_View && <PiEye className='user_action_view' onClick={() => {
        //                 navigate(baseRouteUrl + `/${row.id}/view_client`)
        //             }} />
        //             }
        //             {Is_Clients_Edit && <BiEditAlt className='user_action_edit' onClick={() => {
        //                 navigate(baseRouteUrl + `/${row.id}/update_client`)
        //             }} />}
        //             {Is_Clients_Delete && <MdOutlineDeleteOutline className='user_action_delete' onClick={() => {
        //                 setSelectedId(row.id)
        //             }} />}

        //         </div>
        //     ),
        // },
    ];

    const datas = () => {
        const result =
            auditLogs &&
            auditLogs.map((item, index) => {
                return {
                    key: pageNumber * totalItemPerPage - (totalItemPerPage - (index + 1)),
                    id: item.log_id,
                    event: item.event,
                    activity: item.activity,
                    affected_area: item.affected_area,
                    ip_address: item.ip_address,
                    date_time: item.date_time,
                };
            });
        return result;
    };
    const updatePagination = (pageNumber) => {
        setPageNumber(pageNumber)
    }
    const updateItemPageCount = (pageItemCount) => {
        setTotalItemPerPage(pageItemCount)
    }

    const searchfilter = (value) => {
        setSearchFilter(value)
        if (value) {
            getAuditLogs(pageNumber, value)
        } else {
            getAuditLogs(1, value)
            setPageNumber(1)
        }
    }

    return (
        <div className='clients_module_main_container'>
            <p className='clients_title'>AuditLogs</p>
            <div className='client_module_filter'>
                <AuditLogFilter searchFilter={searchfilter} />
            </div>
            <div className='client_table_main_container'>
                <CustomTable columns={Columns} rows={datas()} />
            </div>
            <div>
                <CustomBottomPagination total_items_per_page={totalItemPerPage} total_page_count={totalPages} current_page={pageNumber} on_change_value={updatePagination} on_page_count_update={updateItemPageCount} />
            </div>
        </div>
    )
}

export default AuditLogs