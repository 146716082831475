import React, { useEffect, useState } from 'react'
import CustomTable from '../../../../../../components/custom_table/CustomTable'
import './flightDateChangeConfirm.css'
import { flight_ticket_date_change } from '../../../../../../api/process_ecr/flightTicketApis'
import { SuccessToast } from '../../../../../../toast/toasts'
import dayjs from 'dayjs'
function FlightDateChangeConfirm({ formDetails, handleCloseCancelConfirmModal, handleCloseAllConfirmModal }) {
  const [passengerCounts, setPassengerCounts] = useState({
    'adult': 0,
    'child': 0,
    'infants': 0
  })
  useEffect(() => {
    if (formDetails?.passegerWithOtherDetails) {
      passengerCount()
    }
  }, [formDetails?.passegerWithOtherDetails])
  const passengerCount = () => {
    let adultCount = 0;
    let childCount = 0;
    let infantCount = 0;

    formDetails?.passegerWithOtherDetails?.forEach((item) => {
      if (item.passenger_type === 1) adultCount++;
      else if (item.passenger_type === 2) childCount++;
      else if (item.passenger_type === 3) infantCount++;
    });

    setPassengerCounts({
      adult: adultCount,
      child: childCount,
      infants: infantCount,
    });
  }
  const Columns = [
    {
      title: "#",
      dataIndex: "key",
      key: "key",

    },
    {
      title: "Title",
      dataIndex: "title_name",
      key: "title_name",
  },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Passenger Type",
      dataIndex: "passenger_type",
      key: "passenger_type",
    },
    {
      title: "New Pnr Number",
      dataIndex: "new_pnr_number",
      key: "new_pnr_number",
    },
    {
      title: "New Ticket Number",
      dataIndex: "new_ticket_number",
      key: "new_ticket_number",
    },
    {
      title: "New Travel Date",
      dataIndex: "new_travel_date",
      key: "new_travel_date",
    },
    {
      title: "New Return Date",
      dataIndex: "new_return_date",
      key: "new_return_date",
    },
    {
      title: "Base Charge",
      dataIndex: "base_change",
      key: "base_change",
    },
    {
      title: "Penality Charge",
      dataIndex: "penality_change",
      key: "penality_change",
    },
    {
      title: "Service Charge",
      dataIndex: "service_charge",
      key: "service_charge",
    },
    {
      title: "Customer Net",
      dataIndex: "customer_net",
      key: "customer_net",
    },
  ]
  const datas = (passengerDetails) => {
    const result =
      passengerDetails &&
      passengerDetails.map((item, index) => {
        console.log(passengerDetails)
        const passengerOtherData = formDetails?.passegerWithOtherDetails.find((data) => data.id === item.FDCD_passangerID)
        return {
          key: index + 1,
          id: item.FBPD_id,
          title_name:passengerOtherData?.title_name,
          name: passengerOtherData?.name,
          passenger_type: passengerOtherData?.passenger_type_name,
          new_pnr_number: item?.FDCD_changed_PNR,
          new_ticket_number: item?.FDCD_ticketnumber,
          new_travel_date: item?.FDCD_changed_travel_date,
          new_return_date: item?.FDCD_changed_return_date,
          base_change: item.FDCD_base_fee,
          penality_change:item.FDCD_change_penalty,
          service_charge: item.FDCD_service_fee,
          gst: item.FDCD_gst,
          customer_net: item.passenger_total_charge,
        };
      });
    return result;
  };
  const onConfirmCancel = () => {
    const restructure_value = JSON.parse(JSON.stringify(formDetails.passengerData))
    restructure_value.forEach(object => {
      delete object['key'];
      delete object['passenger_total_charge'];

    });
    const currentDate = dayjs();
    const formattedDate = currentDate.format('DD-MM-YYYY HH:mm:ss');
    const reStructureResponseData = []
    restructure_value.map((item) => {
      const reStructuredItem = {
        ...item,
        'FDCD_changed_date': formDetails?.basicDetails?.updated_on,
        "FDCD_comment": formDetails?.basicDetails?.remark,
        "Datechange_reason_id": formDetails?.basicDetails?.date_change_reason_id,
        "FDCD_supplier_id": null,
        "FBD_ECRstatusID": 14 ,//Date chnage initiated status
        "FBRH_review_date":formattedDate
      };
      reStructureResponseData.push(reStructuredItem)
    })
    flight_ticket_date_change(reStructureResponseData).then((response) => {
      SuccessToast("Sucesssfully date changed")
      handleCloseAllConfirmModal(false)
    }).catch((error) => {
      console.log(error)
    })
  }
  return (
    <div>
      <div className='flight_date_change_confirm_main_container'>
        <p className="flight_date_change_confirm_main_legend">Reschedule Details</p>
        <div >
          <div className='flight_date_change_confirm_passenger_count'>
            <p>Adult : {passengerCounts.adult}</p>
            <p>Child : {passengerCounts.child}</p>
            <p>Infants : {passengerCounts.infants}</p>
          </div>
          <CustomTable columns={Columns} rows={datas(formDetails?.passengerData)} />
          <div>
            <p className='flight_date_change_confirm_passenger_narration'>Date change reason</p>
            <p className='flight_date_change_confirm_text_1'>{formDetails?.basicDetails?.date_change_reason}</p>

            <p className='flight_date_change_confirm_passenger_narration'>Remarks</p>
            <p className='flight_date_change_confirm_text_1'>{formDetails?.basicDetails?.remark}</p>
          </div>
        </div>
      </div>
      <div className='flight_date_change_confirm_main_container'>
        <p className="flight_date_change_confirm_main_legend">Fare Details</p>
        <div >
          <div>
            <div className="flight_date_change_confirm_main_fair_container">
              <p className='flight_date_change_confirm_text_1'>Updated On</p>
              {/* <div className='dummy_border_line' /> */}
              <p className='flight_date_change_confirm_value_text_1'>{formDetails?.basicDetails?.updated_on}</p>
            </div>

            <div className="flight_date_change_confirm_main_fair_container">
              <p className='flight_date_change_confirm_text_1'>No of Passengers</p>
              {/* <div className='dummy_border_line' /> */}
              <p className='flight_date_change_confirm_value_text_1'>{formDetails?.passengerData?.length}</p>
            </div>
            <div className="flight_date_change_confirm_main_fair_container">
              <p className='flight_date_change_confirm_text_2'>Total Date Change Fee</p>
              {/* <div className='dummy_border_line' /> */}
              <p className='flight_date_change_confirm_value_text_2'>{formDetails?.amount}</p>
            </div>

            <div className="flight_date_change_confirm_main_fair_container">
              <p className='flight_date_change_confirm_text_2'>Total Payable Amount</p>
              {/* <div className='dummy_border_line' /> */}
              <p className='flight_date_change_confirm_value_text_2'>{formDetails?.amount}</p>
            </div>
          </div>
        </div>
      </div>
      <div className='flight_date_change_confirm_single_row_alignment'>
        <button className='flight_date_change_confirm_close_button' onClick={() => {
          handleCloseCancelConfirmModal(false)
        }} >Back</button>
        <button
          onClick={() => {
            onConfirmCancel()
          }}
          className='flight_date_change_confirm_save_button'
        >Proceed</button>
      </div>
    </div>
  )
}

export default FlightDateChangeConfirm