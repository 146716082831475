import React, { useEffect, useState } from 'react'
import './HotelResortFilterStyle.css'
import { useFormik } from 'formik';
import dayjs from 'dayjs';
import CustomTextField from '../../../../../components/custom_text_field/CustomTextField';

function HotelResortFilter({ searchFilter }) {

    const formik = useFormik({
        initialValues: {
            'hotelName': '',
            'email': '',
            'phone': '',
            'city': '',
        },
        onSubmit: (values) => {
            searchFilter(values)
        }
    });
    const resetFilter = () => {
        formik.setValues({
            ...formik.values, ...{
                'hotelName': '',
                'email': '',
                'phone': '',
                'city': '',

            }
        })
        // searchFilter(null)
    }

    return (
        <div className='hotel_filter_main_container'>
            <form id="HotelOrResortsFilterform" onSubmit={formik.handleSubmit}>
                <div className='hotel_filter_sub_container'>
                    <div>
                        <CustomTextField
                            name="hotelName"
                            placeholder={"Enter name"}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.hotelName}
                        />
                    </div>

                    <div>
                        <CustomTextField
                            name="email"
                            placeholder={"Enter email"}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                        />
                    </div>

                    <div>
                        <CustomTextField
                            name="phone"
                            placeholder={"Enter phone"}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.phone}
                        />
                    </div>
                    <div>
                        <CustomTextField
                            name="city"
                            placeholder={"Enter city"}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.city}
                        />
                    </div>

                    {/* Date Range Selection
                    <div className='auditlog_filter_sub_date_selection_container'>
                        <div className='auditlog_filter_style_1'>
                            <p className='auditlog_filter_legent_style_1'> Start Date</p>
                            <CustomDatePicker
                                no_border
                                name="start_date"
                                onChange={(date) => {
                                    formik.setFieldValue("start_date", date);
                                }}
                                onBlur={formik.handleBlur}
                                value={dayjs(formik.values.start_date, "DD-MM-YYYY")}
                                error={formik.touched.start_date && Boolean(formik.errors.start_date)}
                                helperText={formik.touched.start_date && formik.errors.start_date}
                            />
                        </div>

                        <div className='auditlog_filter_date_separator'>
                            <p>--</p>
                        </div>

                        <div className='auditlog_filter_style_1'>
                            <p className='auditlog_filter_legent_style_1'> End Date</p>
                            <CustomDatePicker
                                no_border
                                name="phone"
                                disabled={!formik.values.start_date}
                                minDate={dayjs(formik.values.start_date, "DD-MM-YYYY")}
                                onChange={(date) => {
                                    formik.setFieldValue("phone", date);
                                }}
                                onBlur={formik.handleBlur}
                                value={dayjs(formik.values.phone, "DD-MM-YYYY")}
                                error={formik.touched.phone && Boolean(formik.errors.phone)}
                                helperText={formik.touched.phone && formik.errors.phone}
                            />
                        </div>
                    </div> */}

                    {/* Action Buttons */}
                    <div className='hotel_filter_actions'>
                        <button className='hotel_filter_action_reset' onClick={() => {
                            resetFilter()
                        }}>Reset</button>
                        <button className='hotel_filter_action_search'
                            type="submit"
                            form="HotelOrResortsFilterform"
                        >Search</button>
                    </div>
                </div>

            </form>
        </div>
    )
}

export default HotelResortFilter