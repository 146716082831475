import { ApiConfig } from "./apiConfig";

export const user_login = async (data) => {
    try {
      const response = await ApiConfig.post(`login`,data, { headers: { "Content-Type": "multipart/form-data" }});
      return response;
    } catch (error) {
      throw error;
    }
};

export const user_logout = async () => {
    try {
      const response = await ApiConfig.post(`logout`,{ headers: { "Content-Type": "multipart/form-data" }});
      return response;
    } catch (error) {
      throw error;
    }
};