import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from "yup";
import CustomTextField from '../../../../../../components/custom_text_field/CustomTextField';
import './emailNotificationStyle.css'
import { create_new_email_with_context, delete_email_with_context, get_email_context_lists, get_email_with_context_lists } from '../../../../../../api/generalSettingApis';
import { ErrorToast, SuccessToast } from '../../../../../../toast/toasts';
import { MdDelete } from "react-icons/md";
import { IconButton } from '@mui/material';
import CustomDeleteConfirmModal from '../../../../../../components/custom_delete_confirm_modal/CustomDeleteConfirmModal';

function EmailNotification() {
    const [selectedId, setSelectedId] = useState("");
    const [deleteConfirmation, setDeleteConfirmation] = useState(false);
    const [ContextList, setContextList] = useState([])
    const [emailWithContextList, setEmailWithContextList] = useState([])
    const [checkedContext, setCheckedContext] = useState([])
    useEffect(() => {
        getContextList()
        getEmailWithContextList()
    }, [])

    const getContextList = () => {
        get_email_context_lists().then((response) => {
            if (response?.code == 200) {
                setContextList(response?.data)
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    const getEmailWithContextList = () => {
        get_email_with_context_lists().then((response) => {
            if (response?.code == 200) {
                setEmailWithContextList(response?.data)
            }
            console.log(response)
        }).catch((error) => {
            console.log(error)
        })
    }
    const formik = useFormik({
        initialValues: {
            'email_id': '',
        },
        validationSchema: Yup.object({
            email_id: Yup.string().email("Invalid email address").required("Email is required"),
        }),
        onSubmit: (values, { resetForm }) => {
            const reStructure = {
                email: values?.email_id,
                context: checkedContext && checkedContext.length>0?checkedContext.join(","):''
            }
            create_new_email_with_context(reStructure).then((response) => {
                if (response?.data.code == 200) {
                    SuccessToast(response?.data?.message)
                    resetForm()
                    setCheckedContext([])
                    getEmailWithContextList()
                } else {
                    ErrorToast(response?.data?.message)
                }
            }).catch((error) => {
                console.log(error)
            })
        }
    });
    const handleDeleteModalClose = (is_close) => {
        setDeleteConfirmation(is_close);
        setSelectedId(null)
      };
      const delete_email = () => {
        if (selectedId) {
          delete_email_with_context(selectedId).then((response) => {
            if (response?.data.code == 200) {
                SuccessToast(response?.data?.message)
                handleDeleteModalClose(false)
                getEmailWithContextList()
            } else {
                ErrorToast(response?.data?.message)
            }
          }).catch((error) => {
            console.log(error)
          })
        }
    
      }
    return (
        <div>
            <form id="email_notification_form" onSubmit={formik.handleSubmit}>
                <div className='email_notification_form_main_container'>
                    <p className="email_notification_form_legend">Add Email</p>
                    <div>
                        <div>
                            <p>Email id<span className='label_required_remark'>*</span></p>
                            <div className='email_notification_sub_main_container'>
                                <div className='email_main_container'>
                                    <CustomTextField
                                        name="email_id"
                                        placeholder={"Enter email"}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.email_id}
                                        error={formik.touched.email_id &&
                                            Boolean(formik.errors.email_id)
                                        }
                                        helperText={
                                            formik.touched.email_id && formik.errors.email_id
                                        }
                                    />
                                </div>

                                <div>
                                    <button className='email_notification_form_save_button'
                                        type="submit"
                                        form="email_notification_form"
                                    >Save</button>
                                </div>
                            </div>

                            <div>
                                {ContextList && ContextList.map((item) => (
                                    <div className='email_context_list_item'>
                                        <input type='checkbox' checked={checkedContext.includes(item.context_id)} onChange={() => {
                                            if (checkedContext.includes(item.context_id)) {
                                                const context = checkedContext.filter((item) => item != item.context_id)
                                                setCheckedContext(context)
                                            } else {
                                                setCheckedContext([...checkedContext, item.context_id])
                                            }
                                        }} />
                                        <p>{item?.context}</p>
                                    </div>
                                ))}
                            </div>
                        </div>

                    </div>

                </div>

                <div className='email_notification_form_main_container'>
                    <p className="email_notification_form_legend">Email Lists</p>
                    <div className='email_listing_container'>
                        {emailWithContextList && emailWithContextList.length>0 ?<>
                            {emailWithContextList && emailWithContextList.map((item_1) => (
                            <div className='email_list_item'>
                                <p className='email_label_text' >Email : <span>{item_1.email}</span></p>
                                {ContextList && ContextList.map((item_2) => {
                                    return (
                                        <div className='email_context_list_item'>
                                            <input type='checkbox' checked={item_1?.context.split(',')?.map(Number)?.includes(item_2.context_id)} />
                                            <p>{item_2?.context}</p>
                                        </div>
                                    )
                                })}
                                <div className='email_notification_action_container'>
                                    <IconButton>
                                        <MdDelete className='email_notification_delete_icon' onClick={()=>{
                                            setSelectedId(item_1?.email_context_map_id)
                                            setDeleteConfirmation(true)
                                        }} />
                                    </IconButton>
                                </div>
                            </div>
                        ))}
                        
                        </> :<><p><p>No data available</p></p></>}
                        

                    </div>

                </div>

            </form>
            <CustomDeleteConfirmModal module_name={'email'} open_modal={deleteConfirmation} close_modal={handleDeleteModalClose} confirm_function={delete_email} />
        </div>
    )
}

export default EmailNotification