import { ApiConfig } from "./apiConfig";

export const create_new_package = async (data) => {
  try {
    const response = await ApiConfig.post(`addPackageBooking`, data);
    return response;
  } catch (error) {
    throw error;
  }
};
export const update_new_package = async (data) => {
  try {
    const response = await ApiConfig.post(`updatePackageBooking`, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const get_package_list = async () => {
  try {
    const response = await ApiConfig.post(`getPackagesList`);
    return response.data?.data;

  } catch (error) {
    throw error;
  }
};

export const getpackage_lists_with_pagination = async (data) => {
  try {
    const response = await ApiConfig.post(`getPackagesList`, data, { headers: { "Content-Type": "multipart/form-data" } });
    return response;
  } catch (error) {
    throw error;
  }
};

export const update_package_by_id = async (booking_id, data) => {
  try {
    const response = await ApiConfig.post(`updatePackageBooking`, data, { headers: { "Content-Type": "multipart/form-data" } });
    return response;
  } catch (error) {
    throw error;
  }
};

export const delete_package_by_id = async (id) => {
  try {
    const response = await ApiConfig.post(`deletePackageBooking`, id);
    return response.data;

  } catch (error) {
    throw error;
  }
};
export const get_package_details_by_id = async (data) => {
  try {
    const response = await ApiConfig.post(`getPackageBookingDetails`, data, { headers: { "Content-Type": "multipart/form-data" } });
    return response;
  } catch (error) {
    throw error;
  }
};

export const package_cancel = async (data) => {
  try {
    const response = await ApiConfig.post(`cancelPackageBooking`, data);
    return response;
  } catch (error) {
    throw error;
  }
};


export const approve_or_reject_package = async (data) => {
  try {
    const response = await ApiConfig.post(`changePackageBookingStatus`, data, { headers: { "Content-Type": "multipart/form-data" } });
    return response;
  } catch (error) {
    throw error;
  }
};

export const package_review_comment = async (booking_id) => {
  try {
    const response = await ApiConfig.post(`getPackageBookingHistory`, booking_id);
    return response;
  } catch (error) {
    throw error;
  }
};