import { Pagination } from '@mui/material'
import React from 'react'
import './customBottomPaginationStyle.css'
function CustomBottomPagination({ pageFrom, total_items_per_page, total_page_count, current_page, on_change_value, on_page_count_update }) {

    const ModifiedPagination = {
        "& .MuiPaginationItem-root": {
            fontFamily: "Jost",
            borderColor: "#DFE3E8",
            backgroundColor: "#FFFFFF",
            color: "#212B36",
            "&.Mui-selected": {
                borderColor: "#106262",
                backgroundColor: "#FFFFFF",
                color: "#074D4D",
            },
            "&:hover": {
                borderColor: "#d9dbde",
                backgroundColor: "#FFFFFF",
                color: "#074D4D",
            },
        },
    };
    console.log("pageFrom", pageFrom);

    return (
        <div className='custom_pagination_main_container'>
            <div className='custom_pagination_sub_container_0'>
                <p className='custom_pagination_sub_text_0'>Items Per Page</p>
                <select
                    value={total_items_per_page}
                    className='custom_pagination_per_page_count'
                    onChange={(e) => {
                        on_page_count_update(e.target.value);
                    }}
                >
                    {/* pageFrom FOR HANDLE THE TOTAL PAGE LIMIT FOR REPORT PAGE */}
                    {pageFrom === `sales_report` ? (<>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                    </>) : (<>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                    </>)}
                </select>
            </div>
            <div>
                <Pagination count={total_page_count} page={current_page} variant="outlined" shape="rounded" sx={ModifiedPagination} onChange={(event, value) => {
                    on_change_value(value)
                }} />
            </div>
        </div>
    )
}

export default CustomBottomPagination