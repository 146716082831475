import { ApiConfig } from "../apiConfig";

export const get_flight_ticket_with_pagination = async (data) => {
  try {
    const response = await ApiConfig.post(`listedBookedFlightes`,data,{ headers: { "Content-Type": "multipart/form-data" }});
    return response;
  } catch (error) {
    throw error;
  }
};

export const get_flight_ticket_details_by_id = async (data) => {
  try {
    const response = await ApiConfig.post(`getFlightdetails`,data,{ headers: { "Content-Type": "multipart/form-data" }});
    return response;
  } catch (error) {
    throw error;
  }
};

export const create_new_flight_booking = async (data) => {
    try {
      const response = await ApiConfig.post(`flight_booking`,data);
      return response;
    } catch (error) {
      throw error;
    }
};

export const update_flight_booking = async (booking_id,data) => {
  try {
    const response = await ApiConfig.post(`flight_booking_Update/${booking_id}`,data);
    return response;
  } catch (error) {
    throw error;
  }
};


export const upload_document_for_booking = async (data) => {
  try {
    const response = await ApiConfig.post(`uploadDocuments`,data,{ headers: { "Content-Type": "multipart/form-data" }});
    return response;
  } catch (error) {
    throw error;
  }
};

export const booking_document_delete = async (data) => {
  try {
    const response = await ApiConfig.post(`deleteFlightDocument`,data,{ headers: { "Content-Type": "multipart/form-data" }});
    return response;
  } catch (error) {
    throw error;
  }
};

export const delete_booked_ticket = async (booking_id) => {
  try {
    const response = await ApiConfig.post(`deleteFlightDetails`,{BookingId:booking_id});
    return response;
  } catch (error) {
    throw error;
  }
};

export const approve_or_reject_filght_ticket = async (data) => {
  try {
    const response = await ApiConfig.post(`reviewByManager`,data,{ headers: { "Content-Type": "multipart/form-data" }});
    return response;
  } catch (error) {
    throw error;
  }
};

export const flight_ticket_review_comment = async (booking_id) => {
  try {
    const response = await ApiConfig.post(`reviewComments`,{bookingId:booking_id});
    return response;
  } catch (error) {
    throw error;
  }
};

export const flight_ticket_cancel = async (data) => {
  try {
    const response = await ApiConfig.post(`BookingCancel`,data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const flight_ticket_date_change = async (data) => {
  try {
    const response = await ApiConfig.post(`changeBookingDate`,data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const flight_ticket_cancellation_reasons = async () => {
  try {
    const response = await ApiConfig.post(`cancellationReason`);
    return response.data;
  } catch (error) {
    throw error;
  }
};


export const flight_ticket_booking_review_history=async (booking_id)=>{
  try {
    const response = await ApiConfig.post(`bookingReviewhistory`,{booking_id});
    return response.data;
  } catch (error) {
    throw error
  }
}

export const flight_ticket_date_change_reasons = async () => {
  try {
    const response = await ApiConfig.post(`datechangeReason`);
    return response.data;
  } catch (error) {
    throw error;
  }
};