import React, { useEffect, useState } from 'react'
import './PackagesStyles.css'
import { LocalStorageServices } from '../../../../services/localStorageServices';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CustomTable from '../../../../components/custom_table/CustomTable';
import CustomDeleteConfirmModal from '../../../../components/custom_delete_confirm_modal/CustomDeleteConfirmModal';
import { PiEye } from 'react-icons/pi';
import { BiEditAlt } from 'react-icons/bi';
import { MdOutlineDeleteOutline } from 'react-icons/md';
import { delete_package_by_id, get_package_list, getpackage_lists_with_pagination } from '../../../../api/packageApis';
import { ErrorToast, SuccessToast } from '../../../../toast/toasts';
import PackagesFilter from './packages_filter/PackagesFilter';
import CustomBottomPagination from '../../../../components/custom_bottom_pagination/CustomBottomPagination';
import CancelPackages from './package_modals/CancelPackages';
import { IconButton, Tooltip } from '@mui/material';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { TiTickOutline } from 'react-icons/ti';
import { FaRegCirclePause } from "react-icons/fa6";
import PackagesManagerLevelModal from './packages_manage_level_modals/PackagesManagerLevelModal';
import PackageFinanceLevelModals from './package_finance_level_modals/PackageFinanceLevelModals';
import PackageReusableModals from './package_reusable_modals/PackageReusableModals';
import { SiTicktick } from 'react-icons/si';
import PackageCommentModal from './package_comment_modal/PackageCommentModal';
import { FaRegCommentAlt } from 'react-icons/fa';
import { LiaFileInvoiceSolid } from 'react-icons/lia';
import PackageInvoiceDetailsModal from './package_invoice_details_modal/PackageInvoiceDetailsModal';

function Packages() {
  const navigate = useNavigate()
  // let { booking_id } = useParams();

  const baseRouteUrl = `/ecrs_portal/${LocalStorageServices.getUserRole()}/process_ecr`
  //GET PRIVILEGES DETAILS FROM LOCAL STORAGE
  const getPrivilegesDetailsLocalStorageData = function () {
    const data = localStorage.getItem("privileges_details");
    return data ? JSON.parse(data) : []; // Parse the JSON string or return an empty array if null
  };

  const [packageListing, setPackageListing] = useState([])
  const [pageNumber, setPageNumber] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [totalItemPerPage, setTotalItemPerPage] = useState(10)

  const [selectedId, setSelectedId] = useState("");
  const [searchFilter, setSearchFilter] = useState(null)
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);


  const [selectedItem, setSelectedItem] = useState(null)
  const [cancelModal, setCancelModal] = useState(false);

  const [financeLevelModal, setfinanceLevelModal] = useState(false)
  const [actionModal, setActionModal] = useState(false)
  const [cfoLevelModal, setCfoLevelModal] = useState(false)

  const [commentModal, setcommentModal] = useState(false)
  const [invoiceDetailsModal, setInvoiceDetailsModal] = useState(false)


  // Get privileges details from local storage
  const getPrivilegesDetails = () => JSON.parse(localStorage.getItem("privileges_details") || "[]");

  // Check if a specific privilege exists in User Management -> User
  const hasPrivilege = (privilegeName) =>
    getPrivilegesDetails().some(
      (item) =>
        item.module_name === "Process ECR" &&
        item.submodules?.["Packages"]?.privileges?.some((priv) => priv.name === privilegeName)
    );

  const isPrivileges_Add = hasPrivilege("Add");
  const isPrivileges_View = hasPrivilege("View");
  const isPrivileges_Edit = hasPrivilege("Edit");
  const isPrivileges_Delete = hasPrivilege("Delete");
  const isPrivileges_Hold = hasPrivilege("Hold");
  const isPrivileges_Cancel = hasPrivilege("Cancel");
  const isPrivileges_Reject = hasPrivilege("Reject");
  const isPrivileges_Review = hasPrivilege("Review");
  const isPrivileges_Verify = hasPrivilege("Verify");
  const isPrivileges_Approve = hasPrivilege("Approve");

  useEffect(() => {
    if (!actionModal && !cancelModal && !deleteConfirmation && !financeLevelModal) {
      getHotels(pageNumber, searchFilter)
    }
  }, [pageNumber, totalItemPerPage, deleteConfirmation, cancelModal, actionModal, financeLevelModal]) //deleteConfirmation for temprory page refresh handling 

  const getHotels = (pageNumber, filterValue) => {
    const formData = new FormData();
    formData.append('page', pageNumber);
    formData.append('pageItem_count', totalItemPerPage);
    if (filterValue) {
      for (const key in filterValue) {
        formData.append(key, filterValue[key] ?? '');
      }
    }
    getpackage_lists_with_pagination(formData).then((response) => {
      if (response.data.code === 200) {
        setPackageListing(response.data.data)
        setTotalPages(response.data.pagination?.last_page)
      } else {
        setPackageListing([])
        if (pageNumber > 1) {
          setPageNumber(pageNumber - 1)
        }
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  const Columns = [
    {
      title: "#",
      dataIndex: "key",
      key: "key",

    },
    {
      title: "File Name",
      dataIndex: "filename",
      key: "filename",
    },

    {
      title: "Designation",
      dataIndex: "designation",
      key: "designation",
    },
    {
      title: "Issue Date",
      dataIndex: "issueDate",
      key: "issueDate",
    },
    {
      title: "Hotel",
      dataIndex: "hotel",
      key: "hotel",
    },
    {
      title: "CheckIn",
      dataIndex: "checkIn",
      key: "checkIn",
    },
    {
      title: "CheckOut",
      dataIndex: "checkOut",
      key: "checkOut",
    },
    {
      title: "Service Fee",
      dataIndex: "service_fee",
      key: "service_fee",
    },
    {
      title: "Client",
      dataIndex: "client",
      key: "client",
    },
    {
      title: "Client Amount",
      dataIndex: "client_amount",
      key: "client_amount",
    },
    {
      title: "Gst",
      dataIndex: "gst",
      key: "gst",
    },
    {
      title: "Tcs",
      dataIndex: "tcs",
      key: "tcs",
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },

    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      action: (row) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ display: 'flex', alignItems: 'center', 'gap': 5 }} >
            {isPrivileges_View &&
              <IconButton>
                <PiEye className='packages_action_view' onClick={() => {
                  navigate(baseRouteUrl + `/${row.id}/view_package`)
                }} />
              </IconButton>}
            {/* Staff Action handle */}
            {((row.ecr_status == 1 || row.ecr_status == 6) && (isPrivileges_Edit)) ? (
              <>
                <IconButton>
                  <BiEditAlt className='packages_action_edit' onClick={() => {
                    navigate(baseRouteUrl + `/${row.id}/update_package`)
                  }} />
                </IconButton>

                {isPrivileges_Delete && <Tooltip title="Delete" placement="top">
                  <IconButton>
                    <MdOutlineDeleteOutline className='packages_action_delete' onClick={() => {
                      setSelectedId(row.id)
                      setDeleteConfirmation(true)
                    }} />
                  </IconButton>
                </Tooltip>
                }
              </>

            ) : <></>}
            {row.ecr_status == 5 ? (
              <>
                {isPrivileges_Cancel && <Tooltip title="Cancel" placement="top">
                  <IconButton>
                    <IoCloseCircleOutline className='flight_ticket_action_cancel_ticket' onClick={() => {
                      setSelectedItem(row)
                      setCancelModal(true)
                    }} />
                  </IconButton>
                </Tooltip>

                }
              </>
            ) : <></>}
          </div>

          {/* Finance Action handle */}
          {row.ecr_status === 3 || row.ecr_status === 6 ? (
            <>
              {isPrivileges_Hold && <Tooltip title="Hold" placement="top">
                <IconButton onClick={() => {
                  //setfinanceLevelModal old code
                  setActionModal(true)
                  setSelectedItem({ ...row, 'type': 'Hold' })
                }}>
                  <FaRegCirclePause className='flight_ticket_action_onhold' />
                </IconButton>
              </Tooltip>}
            </>
          ) : <></>}

          {row.ecr_status === 3 || row.ecr_status === 7 || row.ecr_status === 6 ? (
            <>
              {isPrivileges_Verify && <Tooltip title="Verify" placement="top">
                <IconButton onClick={() => {
                  setfinanceLevelModal(true)
                  setSelectedItem({ ...row, 'type': 'Verify' })
                }}>
                  <SiTicktick className='flight_ticket_action_verified' />
                </IconButton>
              </Tooltip>}
            </>

          ) : <></>}

          {(row.ecr_status === 2) ? <>
            {isPrivileges_Review && <Tooltip title="Review" placement="top">
              <IconButton>
                <TiTickOutline className='flight_ticket_action_approve' onClick={() => {
                  setSelectedItem({ ...row, 'type': 'Review' })
                  setActionModal(true)
                }} />
              </IconButton>
            </Tooltip>}
            {isPrivileges_Reject && <Tooltip title="Reject" placement="top">
              <IconButton>
                <IoCloseCircleOutline className='flight_ticket_action_reject' onClick={() => {
                  setSelectedItem({ ...row, 'type': 'Reject' })
                  setActionModal(true)
                }} />
              </IconButton>
            </Tooltip>}

          </> : <></>}

          {/* //CFO */}
          {(row.ecr_status === 4) ? <>
            {isPrivileges_Approve && <Tooltip title="Approve" placement="top">
              <IconButton>
                <TiTickOutline className='flight_ticket_action_approve' onClick={() => {
                  setSelectedItem({ ...row, 'type': 'Approve' })
                  setActionModal(true)
                  // setCfoLevelModal(true)
                }} />
              </IconButton>
            </Tooltip>}

            {isPrivileges_Reject && <Tooltip title="Reject" placement="top">
              <IconButton>
                <IoCloseCircleOutline className='flight_ticket_action_reject' onClick={() => {
                  setSelectedItem({ ...row, 'type': 'CFOReject' })
                  setActionModal(true)
                  // setCfoLevelModal(true)
                }} />
              </IconButton>
            </Tooltip>}

          </> : <></>}
          {true && (
            <IconButton>
              <FaRegCommentAlt className='flight_ticket_action_comment' onClick={() => {
                setSelectedItem({ ...row })
                setcommentModal(true)
              }} />
            </IconButton>
          )}
          <Tooltip title="Invoice" placement="top">
            <IconButton>
              <LiaFileInvoiceSolid className='flight_ticket_action_invoice' onClick={() => {
                setInvoiceDetailsModal(true)
                setSelectedItem(row?.all_data)
              }} />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  const deletePackage = () => {
    if (selectedId) {
      const payload = {
        booking_id: selectedId
      }
      delete_package_by_id(payload).then((response) => {
        if (response.code === 200) {
          handleDeleteModalClose(false)
          SuccessToast(response?.message)
        }
      }).catch((error) => {
        handleDeleteModalClose(false)
        ErrorToast(error?.data?.message)
      })
    }
  }
  const handleDeleteModalClose = (is_close) => {
    setDeleteConfirmation(is_close);
    setSelectedId(null)
  };

  const datas = () => {
    const result =
      packageListing &&
      packageListing.map((item, index) => {
        return {
          key: pageNumber * totalItemPerPage - (totalItemPerPage - (index + 1)),
          id: item.booking_id,
          filename: item.filename,
          designation: item.designation,
          issueDate: item.issueDate,
          hotel: item.hotel,
          checkIn: item.checkIn,
          checkOut: item.checkOut,
          // service: item.service,
          service_fee: item.service_fee,
          client: item.client,
          client_amount: item.client_amount,
          gst: item.gst,
          tcs: item.tcs,
          status: item.status,
          ecr_status: item.status_id
        };
      });
    return result;
  };


  const updatePagination = (pageNumber) => {
    setPageNumber(pageNumber)
  }
  const updateItemPageCount = (pageItemCount) => {
    setTotalItemPerPage(pageItemCount)
  }

  const searchfilter = (value) => {
    setSearchFilter(value)
    if (value) {
      getHotels(pageNumber, value)
    } else {
      getHotels(1, value)
      setPageNumber(1)
    }
  }

  const handleModalClose = (is_close) => {
    setCancelModal(is_close)
    setSelectedItem(null)
  };


  //MANAGER LELE MODAL CLOSE
  const handleActionModalClose = (is_close) => {
    setActionModal(is_close)
    setSelectedItem(null)
  };

  const handleFinanceLevelModalClose = (is_close) => {
    setfinanceLevelModal(is_close)
    setSelectedItem(null)
  };
  const handleCfoLevelModalClose = (is_close) => {
    setCfoLevelModal(is_close)
    setSelectedItem(null)
  };
  const handleCommentModalClose = (is_close) => {
    setcommentModal(is_close)
    setSelectedItem(null)
  };
  
  const handleInvoiceDetailsModalClose = (is_close) => {
    setInvoiceDetailsModal(is_close)
    setSelectedItem(null)
  };

  return (

    <div className='packages_module_main_container'>
      <p className='packages_title'>Packages</p>
      <div>

        <div className='user_module_filter'>
          <PackagesFilter searchFilter={searchfilter} />
          {isPrivileges_Add && <button className='packages_add_button' onClick={() => {
            navigate(baseRouteUrl + `/create_new_package`)
          }}>Add New</button>}
        </div>
        <div className='packages_table_main_container'>
          <CustomTable columns={Columns} rows={datas()} />
        </div>
        <div>
          <CustomBottomPagination total_items_per_page={totalItemPerPage} total_page_count={totalPages} current_page={pageNumber} on_change_value={updatePagination} on_page_count_update={updateItemPageCount} />
        </div>
      </div>
      <CustomDeleteConfirmModal module_name={'Packages'} open_modal={deleteConfirmation} close_modal={handleDeleteModalClose} confirm_function={deletePackage} />
      <CancelPackages selected_item={selectedItem} open_modal={cancelModal} modal_close={handleModalClose} />

      <PackageReusableModals selected_item={selectedItem} open_modal={actionModal} modal_close={handleActionModalClose} />
      <PackageCommentModal open={commentModal} handleClose={handleCommentModalClose} booking_id={selectedItem?.id} />

      {/* <PackagesManagerLevelModal selected_item={selectedItem} open_modal={actionModal} modal_close={handleActionModalClose} /> */}
      <PackageFinanceLevelModals selected_item={selectedItem} open_modal={financeLevelModal} modal_close={handleFinanceLevelModalClose} />
      {invoiceDetailsModal && <PackageInvoiceDetailsModal selected_item={selectedItem} open_modal={invoiceDetailsModal} modal_close={handleInvoiceDetailsModalClose} />}

    </div>
  )
}

export default Packages