import React, { useEffect, useState } from 'react'
import './generalSettingsStyle.css'
import EmailNotification from './sub_components/email_notification/EmailNotification'

function GeneralSettings() {
    const [selectedTab,setSelectedTab]=useState(0)
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get('selected');
    const tabs=[{
        key:0,
        name:"Email notification settings"
    },
    {
        key:1,
        name:"Tax settings"
    }]

    useEffect(()=>{
        setSelectedTab(myParam)
    },[myParam])


  return (
    <div className='general_settings_module_main_container'>
        <div className='general_settings_tabs_main_container'>
            {tabs && tabs.map((item)=>(
               <p className= {item.key==selectedTab?'selected_general_settings_tabs':'un_selected_general_settings_tabs'}>{item.name}</p>
            ))}
        </div>
        <div>
            <EmailNotification/>
        </div>
    </div>
  )
}

export default GeneralSettings