import { ApiConfig } from "./apiConfig";

export const get_supplier_with_pagination = async (data) => {
    try {
      const response = await ApiConfig.post(`suppliers`,data,{ headers: { "Content-Type": "multipart/form-data" }});
      return response;
    } catch (error) {
      throw error;
    }
};
export const get_supplier_without_pagination = async (data) => {
  try {
    const response = await ApiConfig.post(`suppliers`,{ headers: { "Content-Type": "multipart/form-data" }});
    return response;
  } catch (error) {
    throw error;
  }
};
export const get_supplier_by_id = async (supplier_id) => {
    try {
      const response = await ApiConfig.post(`suppliersByid`,{"SD_id":supplier_id},{ headers: { "Content-Type": "multipart/form-data" }});
      return response.data;
    } catch (error) {
      throw error;
    }
};
export const supplier_registration = async (data) => {
    try {
      const response = await ApiConfig.post(`suppliers_register`,data, { headers: { "Content-Type": "multipart/form-data" }});
      return response;
    } catch (error) {
      throw error;
    }
};

export const update_supplier = async (data) => {
    try {
      const response = await ApiConfig.post(`suppliers_Update`,data, { headers: { "Content-Type": "multipart/form-data" }});
      return response;
    } catch (error) {
      throw error;
    }
};

export const update_supplier_bankdetails = async (data) => {
  try {
    const response = await ApiConfig.post(`suppliers_Update_Bank`,data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const delete_supplier = async (supplier_id) => {
    try {
      const response = await ApiConfig.delete(`suppliersDelete/${supplier_id}`,{ headers: { "Content-Type": "multipart/form-data" }});
      return response;
    } catch (error) {
      throw error;
    }
};