import { ApiConfig } from "./apiConfig";

export const get_email_context_lists = async () => {
    try {
      const response = await ApiConfig.post(`getEmailContext`);
      return response?.data;
    } catch (error) {
      throw error;
    }
};
export const get_email_with_context_lists=async ()=>{
  try {
    const response = await ApiConfig.post(`getEmailContextList`);
    return response?.data;
  } catch (error) {
    throw error;
  }
}
export const create_new_email_with_context = async (data) => {
    try {
      const response = await ApiConfig.post(`saveMailContext`,data);
      return response;
    } catch (error) {
      throw error;
    }
};

export const delete_email_with_context=async(id)=>{
  try {
    const response = await ApiConfig.post(`deleteMailContext`,{context_id:id});
    return response;
  } catch (error) {
    throw error;
  }
}